import { Group } from 'dataformjs';
import React, { FC, SyntheticEvent } from 'react';

import Input from '../../../../../../components/Input';
import Select from '../../../../../../components/Select';

const Titre: FC<{
  data?: firebase.default.firestore.DocumentData;
  onChange: (attr: string, value: any) => void;
}> = ({ data, onChange }) => {
  const handleOnChange = (
    event: SyntheticEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { value } = event.currentTarget;
    const attr = event.currentTarget.getAttribute('data-attr');

    if (attr) {
      onChange(attr, value);
    }
  };

  const options = {
    headerAlignement: [
      { label: 'Centré', value: 'center' },
      { label: 'A gauche', value: 'left' },
      { label: 'A droite', value: 'right' },
      { label: 'Justifié', value: 'justify' },
    ],
    headerType: [
      { label: 'H1', value: 'h1' },
      { label: 'H2', value: 'h2' },
      { label: 'H3', value: 'h3' },
      { label: 'H4', value: 'h4' },
      { label: 'H5', value: 'h5' },
      { label: 'H6', value: 'h6' },
      { label: 'Caché', value: 'hidden' },
    ],
  };

  return (
    <div>
      <Group title="Titre">
        <Input
          data-attr="header"
          label="Titre"
          name="header"
          onChange={handleOnChange}
          type="text"
          value={data?.header}
        />
        <Select
          data-attr="headerAlignement"
          label="Type"
          name="headerType"
          onChange={handleOnChange}
          options={options.headerType}
          value={data?.headerType}
        />
        <Select
          data-attr="headerAlignement"
          label="Alignement"
          name="headerAlignement"
          onChange={handleOnChange}
          options={options.headerAlignement}
          value={data?.headerAlignement}
        />
        <Input
          data-attr="headerDate"
          label="Date"
          name="headerDate"
          onChange={handleOnChange}
          type="date"
          value={data?.headerDate}
        />
        <Input
          data-attr="headerLink"
          label="Lien"
          name="headerLink"
          onChange={handleOnChange}
          type="text"
          value={data?.headerLink}
        />
        <Input
          data-attr="headerSubTitle"
          label="Sous titre"
          name="headerSubTitle"
          onChange={handleOnChange}
          type="text"
          value={data?.headerSubTitle}
        />
      </Group>
    </div>
  );
};

export default Titre;
