import React, { FC, SyntheticEvent } from 'react';

import Tabs from '../../../../../components/Tabs';
import Modal from '../../../../../components/View/Modal';
import Images from './tabs/Images';
import Produits from './tabs/Produits';
import Text from './tabs/Text';
import Titre from './tabs/Titre';

interface ContentProps {
  boutique?: firebase.default.firestore.DocumentSnapshot;
  closeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  onChange: (attr: string, value: any) => void;
  docId: string;
  item: firebase.default.firestore.DocumentSnapshot;
}
const Content: FC<ContentProps> = ({
  boutique,
  docId,
  closeOnClick,
  onChange,
  item,
}) => {
  const handleOnBlur = () => {
    console.info('handleOnBlur');
  };

  const buttons = [
    {
      className: 'close',
      onClick: closeOnClick,
      primary: true,
      text: 'Fermer',
    },
  ];

  const data = item.data();

  const TabTexte = (
    <Text
      data={data}
      // handleOnBlur={handleOnBlur}
      onChange={onChange}
    />
  );

  const TabTitre = <Titre data={data} onChange={onChange} />;

  const TabImages = (
    <Images
      boutique={boutique}
      data={data}
      docId={docId}
      handleOnBlur={handleOnBlur}
      onChange={onChange}
    />
  );

  const TabProduits = (
    <Produits
      boutique={boutique}
      data={data}
      docId={docId}
      handleOnBlur={handleOnBlur}
      handleOnChangeField={onChange}
    />
  );

  return (
    <Modal buttons={buttons} closeOnClick={closeOnClick} title="Contenu">
      <Tabs
        items={[
          {
            content: TabTexte,
            name: 'Texte',
          },
          {
            content: TabTitre,
            name: 'Titre',
          },
          {
            content: TabImages,
            name: 'Images',
          },
          {
            content: TabProduits,
            name: 'Produits',
          },
        ]}
      />
    </Modal>
  );
};

export default Content;
