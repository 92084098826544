import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Draft = styled.div`
  border: 1px solid #d7d6d9;
  border-radius: 4px;
  width: 100%;

  .controls {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #fbfbfc;
    border-bottom: 1px solid #d7d6d9;
  }

  .editor {
    padding: 10px;
  }

  .RichEditor-controls {
    font-size: 12px;
    margin: 0;
    user-select: none;
  }

  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 10px;
    padding: 2px 4px;
    display: inline-block;
    line-height: 18px;
  }

  .RichEditor-activeButton {
    color: #5890ff;
  }

  .RichEditor-linkControl {
    position: relative;

    .popup-input {
      position: absolute;
      top: 20px;
      background: #f9f9f9;
      padding: 0.375rem;
      width: 300px;
      left: 20px;
      input {
        width: calc(100% - 1.5rem);
      }
    }

    .buttons {
      text-align: right;
      button {
        margin-left: 0.375rem;
      }
    }
  }
`;
