import { DataProps } from 'dataformjs';
import PropTypes from 'prop-types';
import React, { FC } from 'react';

import List from './List';

const Data: FC<
  DataProps & {
    type: string;
  }
> = props => {
  const { params, type } = props;

  switch (type) {
    case 'list':
      if (!params || !params.docId) {
        return (
          <div>
            content - list : erreur de paramètre : params.docId est obligatoire
          </div>
        );
      }
      if (!params || !params.docRef) {
        return (
          <div>
            content - list : erreur de paramètre : params.docRef est obligatoire
          </div>
        );
      }

      return <List {...props} docId={params.docId} docRef={params.docRef} />;

    default:
  }

  return <div>Content</div>;
};

Data.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Data;
