import { Link } from 'gatsby';
import React, { FC, useEffect, useRef } from 'react';

import { checkIfIsVisible } from '../../utils/functions';
import Icon from '../Icon';
import { ButtonProps } from './index';
import ButtonSC from './style';

const ButtonIsVisible: FC<ButtonProps> = ({
  children,
  clickOnVisible = false,
  iconColor,
  iconLeft,
  iconRight,
  iconSize,
  isInfinite = false,
  text,
  to,
  type = 'button',
  ...others
}) => {
  const nodeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const isVisible = () => {
      if (
        !nodeRef ||
        !nodeRef.current ||
        !nodeRef.current.getBoundingClientRect
      ) {
        return;
      }

      const html = document.documentElement;
      const boundingClientRect = nodeRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth || html.clientWidth;
      const windowHeight = window.innerHeight || html.clientHeight;

      if (checkIfIsVisible(boundingClientRect, windowWidth, windowHeight)) {
        console.info('is visible');

        if (!isInfinite) {
          removeListener();
        }

        if (clickOnVisible) {
          nodeRef.current.click();
        }
      }
    };

    const attachListener = () => {
      window.addEventListener('scroll', isVisible);
      window.addEventListener('resize', isVisible);
    };

    const removeListener = () => {
      window.removeEventListener('scroll', isVisible);
      window.removeEventListener('resize', isVisible);
    };

    if (clickOnVisible) {
      attachListener();
      isVisible();
    }

    return () => {
      removeListener();
    };
  }, [clickOnVisible, isInfinite, nodeRef]);

  const props: { [key: string]: any } = {};
  if (to) {
    props.to = to;
    props.as = Link;
  }

  return (
    <ButtonSC ref={nodeRef} {...others} {...props}>
      {iconLeft && <Icon color={iconColor} size={iconSize} value={iconLeft} />}
      {children}

      {text && <span className="text">{text}</span>}
      {iconRight && (
        <Icon color={iconColor} size={iconSize} value={iconRight} />
      )}
    </ButtonSC>
  );
};

export default ButtonIsVisible;
