import React, {
  FC,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Textarea as TextareaSC } from './styles';

const AutoTextarea: FC<TextareaHTMLAttributes<HTMLTextAreaElement>> = props => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState<string>('');
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const [parentHeight, setParentHeight] = useState('auto');

  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      setParentHeight(`${textAreaRef.current.scrollHeight}px`);
      setTextAreaHeight(`${textAreaRef.current.scrollHeight}px`);
    }
  }, [text]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaHeight('auto');
    if (textAreaRef && textAreaRef.current) {
      setParentHeight(`${textAreaRef.current.scrollHeight}px`);
    }

    setText(event.target.value);

    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div
      style={{
        minHeight: parentHeight,
      }}
    >
      <TextareaSC
        {...props}
        ref={textAreaRef}
        onChange={onChangeHandler}
        rows={1}
        style={{
          height: textAreaHeight,
        }}
      />
    </div>
  );
};

export default AutoTextarea;
