import { BoutiqueData, ProduitData } from '@innedit/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import defaultImage from '../../../../../../images/next-photo.svg';

const Item: FC<{
  boutique: any;
  handleRemoveProduct?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  itemId: string;
  mode: string;
  showName?: boolean;
}> = ({ boutique, handleRemoveProduct, itemId, mode, showName }) => {
  const [produit, setProduit] =
    useState<firebase.default.firestore.DocumentSnapshot>();

  useEffect(() => {
    let unsub: () => void;
    if (boutique && itemId) {
      const produitModel = new ProduitData({ boutique });
      unsub = produitModel.watchById(itemId, snapshot => {
        setProduit(snapshot);
      });
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique, itemId]);

  if (!produit) {
    return null;
  }

  switch (mode) {
    case 'show':
      if (produit.get('photos') && produit.get('photos').length > 0) {
        if (showName) {
          return (
            <div>
              <figure>
                <img
                  alt={produit.get('name')}
                  src={`${BoutiqueData.getDomainImagesURL(boutique)}/${
                    produit.get('photos')[0]
                  }?h=320&w=320&c=max`}
                />
              </figure>
              <h3>{produit.get('name')}</h3>
            </div>
          );
        }

        return (
          <img
            alt={produit.get('name')}
            src={`${BoutiqueData.getDomainImagesURL(boutique)}/${
              produit.get('photos')[0]
            }?h=320&w=320&c=max`}
          />
        );
      }

      return null;

    case 'edit':
      return (
        <div>
          <div className="produit">
            <figure className="aspect-ratio aspect-ratio--square">
              {produit.get('photos') ? (
                <img
                  alt={produit.get('name')}
                  className="aspect-ratio__content"
                  src={`${BoutiqueData.getDomainImagesURL(boutique)}/${
                    produit.get('photos')[0]
                  }?h=320&w=320&c=max`}
                />
              ) : (
                <img
                  alt={produit.get('name')}
                  className="aspect-ratio__content"
                  src={defaultImage}
                />
              )}
            </figure>
            <h3>{produit.get('name')}</h3>

            <button
              data-id={itemId}
              onClick={handleRemoveProduct}
              type="button"
            >
              Retirer
            </button>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default Item;
