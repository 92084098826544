import { BoutiqueData, ProduitData, ProduitType } from '@innedit/innedit';
import React, { FC, KeyboardEvent, SyntheticEvent, useState } from 'react';

import ActionBar from '../../../../../CMS/components/ActionBar';
import Item from './Item';
import { ProduitsList as StyledProduitsList } from './styles';

const ProduitCRUD: FC<{
  boutique?: firebase.default.firestore.DocumentSnapshot;
  items: string[];
  updateItems: (values: any) => void;
}> = ({ boutique, items, updateItems }) => {
  const [q, setQ] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [hits, setHits] = useState<ProduitType[]>();
  const [nbHits, setNbHits] = useState<number>();
  // this.state = {
  //   error: false,
  //   message: '',
  //   q: '',
  //   searchProduits: undefined,
  // };

  const handleOnChangeSearch = (e: SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();

    const { value } = e.currentTarget;

    setQ(value);
  };

  const handleOnKeyPressSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (13 === e.which) {
      e.preventDefault();
      loadSearchProduits();
    }
  };

  const handleEmptySearch = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setQ('');
    setHits(undefined);
    setNbHits(undefined);
  };

  const handleSelectThisProduct = (e: SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault();

    const id = e.currentTarget.getAttribute('data-id');
    if (id) {
      const produits = items && items.length > 0 ? items.slice(0) : [];
      produits.push(id);
      updateItems(produits);
      setQ('');
      setHits(undefined);
      setNbHits(undefined);
    }
  };

  const handleRemoveProduct = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const id = e.currentTarget.getAttribute('data-id');
    const produits = items && items.length > 0 ? items.slice(0) : [];

    const index = produits.findIndex((produitId: string) => produitId === id);
    produits.splice(index, 1);
    updateItems(produits);
  };

  const loadSearchProduits = () => {
    if (q) {
      const produitData = new ProduitData({
        boutique,
      });
      produitData
        .search(q)
        .then(produits => {
          setMessage(undefined);
          setNbHits(produits.nbHits);

          return setHits(produits.hits);
        })
        .catch(error => {
          setMessage(error.message);
        });
    }
  };

  if (message) {
    return (
      <div>
        <h1>Erreur</h1>
        <p>{message}</p>
      </div>
    );
  }

  return (
    <div>
      <ActionBar className="action-bar secondary is-clearfix">
        <div className="menu-annexe">
          <ul>
            <li className="navbar-item search">
              <input
                onChange={handleOnChangeSearch}
                onKeyPress={handleOnKeyPressSearch}
                placeholder="Rechercher"
                type="text"
                value={q}
              />
              {q && (
                <button
                  className="empty"
                  onClick={handleEmptySearch}
                  type="button"
                >
                  <span className="icon" />
                  <span className="text">Vider</span>
                </button>
              )}
            </li>
          </ul>
        </div>

        {nbHits}

        {hits && hits.length > 0 && (
          <div className="search-results">
            {hits.map(produit => (
              <div
                key={produit.id}
                className="search-results-item"
                data-id={produit.id}
                onClick={handleSelectThisProduct}
                role="presentation"
              >
                {produit.photos && (
                  <div className="search-results-item-image">
                    <figure className="aspect-ratio aspect-ratio--square">
                      <img
                        alt={produit.name}
                        className="aspect-ratio__content"
                        src={`${BoutiqueData.getDomainImagesURL(boutique)}/${
                          produit.photos[0]
                        }?h=64&w=64&c=max`}
                      />
                    </figure>
                  </div>
                )}

                <h3>{produit.name}</h3>
              </div>
            ))}
          </div>
        )}
      </ActionBar>
      {items && items.length > 0 && (
        <StyledProduitsList>
          {items.map(produitId => (
            <Item
              key={produitId}
              boutique={boutique}
              handleRemoveProduct={handleRemoveProduct}
              itemId={produitId}
              mode="edit"
              showName
            />
          ))}
        </StyledProduitsList>
      )}
    </div>
  );
};

export default ProduitCRUD;
