import { DataArrayProps, FieldArray } from 'dataformjs';
import React, { FC } from 'react';

import ListRender from './Render';

export interface ListProps extends DataArrayProps {
  formName: string;
  name: string;
}

const List: FC<ListProps> = props => {
  const { name } = props;

  return <FieldArray component={ListRender} name={name} props={props} />;
};

export default List;
