import { Group } from 'dataformjs';
import React, { FC } from 'react';

import Produits from '../../../../../../../Boutique/containers/Produit/components/Crud';
import Input from '../../../../../../components/Input';
import Select from '../../../../../../components/Select';

interface ProduitsTabProps {
  boutique?: firebase.default.firestore.DocumentSnapshot;
  data: any;
  docId: string;
  handleOnBlur: () => void;
  handleOnChangeField: (attr: string, value: any) => void;
}
const ProduitsTab: FC<ProduitsTabProps> = ({
  data,
  boutique,
  handleOnBlur,
  handleOnChangeField,
}) => {
  const options = {
    produitsNbColumns: [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
    ],
    produitsPosition: [
      {
        label: 'Centré au dessus',
        value: 'above-center',
      },
      {
        label: 'Centré en dessous',
        value: 'below-center',
      },
      {
        label: 'A droite du texte',
        value: 'beside-text-right',
      },
      {
        label: 'A gauche du texte',
        value: 'beside-text-left',
      },
      {
        label: 'Centré à droite du texte',
        value: 'beside-text-right-centered',
      },
      {
        label: 'Centré à gauche du texte',
        value: 'beside-text-left-centered',
      },
    ],
  };

  return (
    <div>
      <Produits
        boutique={boutique}
        items={data.produits ? data.produits : []}
        updateItems={(value: any) => handleOnChangeField('produits', value)}
      />
      <Group title="Options">
        <Select
          label="Position et alignement"
          name="produitsPosition"
          onChange={handleOnBlur}
          options={options.produitsPosition}
          value={data.produitsPosition}
        />
        <Select
          label="Nombre de colonnes"
          name="produitsNbColumns"
          onChange={handleOnBlur}
          options={options.produitsNbColumns}
          value={data.produitsNbColumns}
        />
        <Input
          description="Cliquer pour agrandir"
          label="Agrandir"
          name="produitsClickToEnlarge"
          onChange={handleOnBlur}
          type="checkbox"
          value={data.produitsClickToEnlarge}
        />
        <Input
          description="Cliquer pour voir"
          label="Voir le produit"
          name="produitsClickToShow"
          onChange={handleOnBlur}
          type="checkbox"
          value={data.produitsClickToShow}
        />
        <Input
          description="Est-ce que le nom est visible ?"
          label="Voir le nom du produit"
          name="produitsShowName"
          onChange={handleOnBlur}
          type="checkbox"
          value={data.produitsShowName}
        />
      </Group>
    </div>
  );
};

export default ProduitsTab;
