import { DataProps, Form, submit, useDispatch } from 'dataformjs';
import moment from 'moment';
import React, { FC, SyntheticEvent } from 'react';

import IconSave from '../../../../../../../icons/Save';
import ViewModal from '../../../../../components/View/Modal';

interface ModalProps {
  closeOnClick: (event?: SyntheticEvent<HTMLButtonElement>) => void;
  formName: string;
  doc: firebase.default.firestore.DocumentSnapshot;
  modalDatas?: DataProps | DataProps[];
  onChange: (
    event: SyntheticEvent<HTMLSelectElement | HTMLInputElement>,
  ) => void;
  title?: string;
}

const Modal: FC<ModalProps> = ({
  closeOnClick,
  formName,
  doc,
  modalDatas,
  title,
}) => {
  const dispatch = useDispatch();

  const handleOnSubmit = async (values: any) => {
    console.info('mise à jour du document', values);
    await doc.ref.update({
      ...values,
      updatedAt: moment().toISOString(),
    });

    closeOnClick();
  };

  const handleSubmitFormOnClick = () => {
    dispatch(submit(formName));
  };

  if (!title) {
    return <div>Le titre est obligatoire</div>;
  }

  console.info('formName modal', formName);

  return (
    <ViewModal
      buttons={[
        { className: 'all-grey', onClick: closeOnClick, text: 'Fermer' },
        {
          onClick: handleSubmitFormOnClick,
          status: 'primary',
          text: 'Enregistrer et fermer',
        },
      ]}
      closeOnClick={closeOnClick}
      title={title}
    >
      {modalDatas && (
        <Form
          // cancelProps={{
          //   className: 'flex justify-center items-center space-x-1.5',
          //   icon: IconClose,
          //   label: 'Fermer',
          //   onClick: closeOnClick,
          // }}
          datas={modalDatas}
          hideSubmitButton
          initialValues={doc.data()}
          name={formName}
          onSubmit={handleOnSubmit}
          params={{ doc }}
          submitProps={{
            className: 'flex flex-row justify-center items-center space-x-1.5',
            iconRight: IconSave,
            label: 'Enregistrer et fermer',
          }}
        />
      )}
    </ViewModal>
  );
};

export default Modal;
