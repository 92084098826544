import { change, connect, DataField, Dispatch } from 'dataformjs';
import React, { FC, useEffect } from 'react';

interface POSProps {
  formName: string;
}
const POS: FC<
  POSProps & {
    dispatch: Dispatch;
    hasInventory: boolean;
    inStock: boolean;
    isInPOS: boolean;
    qtyAvailable: number;
  }
> = ({ dispatch, formName, hasInventory, isInPOS, inStock, qtyAvailable }) => {
  useEffect(() => {
    if (hasInventory && isInPOS && !inStock) {
      dispatch(change(formName, 'isInPOS', false));
      dispatch(change(formName, 'qtyAvailable', 0));
    }
  }, [dispatch, formName, hasInventory, isInPOS, inStock]);

  const disabled = hasInventory ? !qtyAvailable || qtyAvailable <= 0 : !inStock;

  return (
    <DataField
      className="pointofsale"
      componentType="input"
      description="Est disponible au magasin ?"
      disabled={disabled}
      label="Point de vente"
      name="isInPOS"
      type="checkbox"
    />
  );
};

const mapStateToProps = (globalState: any, ownProps: POSProps) => {
  const { values } = globalState.form[ownProps.formName];

  return {
    hasInventory: values.hasInventory,
    inStock: values.inStock,
    isInPOS: values.isInPOS,
    qtyAvailable: values.qtyAvailable,
  };
};

export default connect(mapStateToProps)(POS);
