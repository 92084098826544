import { WrappedFieldArrayProps } from 'dataformjs';
import React, { FC } from 'react';

import { CaracteristiquesProps } from './index';
import Item from './Item';

const Render: FC<
  WrappedFieldArrayProps & CaracteristiquesProps & { features: any }
> = ({ boutique, fields, features }) => (
  <>
    {fields.map((field, idx, f) => (
      <Item
        key={f.get(idx).id}
        boutique={boutique}
        data={f.get(idx)}
        docRef={features && features[idx] && features[idx].ref}
        handleRemove={() => fields.remove(idx)}
        name={field}
      />
    ))}
  </>
);

export default Render;
