import classnames from 'classnames';
import React, { FC, SyntheticEvent } from 'react';

import StyledField from '../styles/Field';

const Select: FC<{
  className?: string;
  label: string;
  name: string;
  noDefaultOption?: boolean;
  onChange: (e: SyntheticEvent<HTMLSelectElement>) => void;
  options: { label: string; value: string | number }[];
  value?: string | number;
}> = ({
  className,
  label,
  name,
  noDefaultOption = false,
  onChange,
  options,
  value,
  ...others
}) => (
  <StyledField className={classnames(className, 'select')}>
    <select name={name} onChange={onChange} value={value} {...others}>
      {!noDefaultOption && <option value="">Non spécifié</option>}
      {options &&
        options.map((option, index) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
    </select>
    <div className="label">
      <span>{label}</span>
    </div>
  </StyledField>
);

export default Select;
