import React, { FC } from 'react';
import styled from 'styled-components';

import { spacing } from '../../styles/theme';

const Translation = styled.div`
  margin-top: ${spacing[3]};
`;

export const TranslationItem = styled.div`
  position: relative;
  padding-left: 30px;
  margin-top: ${spacing[1.5]};
`;
export const TranslationItemLang: FC<{ lang: string }> = ({ lang }) => (
  <div className="absolute left-0">{lang}</div>
);

export default Translation;
