import React, { FC, ReactNode, SyntheticEvent, useState } from 'react';
import slug from 'slug';

import StyledTabs from '../styles/Tabs';

const Tabs: FC<{ items: { name: string; content: ReactNode }[] }> = ({
  items,
}) => {
  const [index, setIndex] = useState<number>(0);

  const handleSetTab = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const idx = e.currentTarget.getAttribute('data-index');
    if (idx) {
      setIndex(parseInt(idx, 10));
    }
  };

  return (
    <StyledTabs>
      <ul>
        {items.map((tab, idx) => (
          <li key={slug(tab.name)} className={index === idx ? 'is-active' : ''}>
            <button data-index={idx} onClick={handleSetTab} type="button">
              {tab.name}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab">{items[index].content}</div>
    </StyledTabs>
  );
};

export default Tabs;
