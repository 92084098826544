import styled from 'styled-components';

export default styled.h1`
  color: #34313f;
  font-size: 30px;
  font-weight: 300;
  line-height: 1.3334;
  margin-bottom: 30px;

  input {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  span:after {
    content: ' ';
  }
`;
