import { Field } from 'dataformjs';
import React, { FC } from 'react';

import Notification from '../containers/Backoffice/Notification';
import { SearchProps } from '../containers/Boutique/components/new/props';
import ViewTitle from '../styles/View/Title';
import Menu, { MenuProp } from './Menu';

const MyCustomInput: FC<{ input: any }> = ({ input: { value } }) => (
  <span>{value}</span>
);

type TitleProps = string | string[] | any;

const Title: FC<TitleProps> = ({ title }) => {
  if (Array.isArray(title)) {
    return (
      <ViewTitle>
        {title.map(item => (
          <Field
            key={`title_${item.attribut}`}
            component={MyCustomInput}
            disabled
            name={item.attribut}
          />
        ))}
      </ViewTitle>
    );
  }

  if ('object' === typeof title) {
    return (
      <ViewTitle>
        <Field component={MyCustomInput} disabled name={title.attribut} />
      </ViewTitle>
    );
  }

  return title ? <ViewTitle>{title}</ViewTitle> : null;
};

const Header: FC<{
  menu?: MenuProp;
  search?: SearchProps;
  title?: TitleProps;
}> = ({ title, menu, search }) => (
  <nav className="flex flex-col">
    {title && <Title title={title} />}
    <Notification />
    <Menu menu={menu} search={search} />
  </nav>
);

export default Header;
