import { UrlData } from '@innedit/innedit';
import classnames from 'classnames';
import {
  change,
  DataField,
  DataFieldInputProps,
  useDispatch,
  useSelector,
} from 'dataformjs';
import React, { FC, useState } from 'react';
import slug from 'slug';

import Button from '../../../../../components/Button';
import IconSave from '../../../../../icons/Save';

export interface LibelleProps extends DataFieldInputProps {
  boutique: firebase.default.firestore.DocumentSnapshot;
  docId: string;
  formName: string;
  isTranslatable?: boolean;
  name: string;
  pathname?: string;
  parentId?: string;
  traductions?: any;
  urlType: 'page' | 'post' | 'produit';
}

const Libelle: FC<Omit<LibelleProps, 'componentType'>> = ({
  boutique,
  docId,
  formName,
  name,
  urlType,
  ...props
}) => {
  const [updateAvailable, setUpdateAvailable] = useState<boolean>();
  const dispatch = useDispatch();
  const values = useSelector(
    (globalState: any) => globalState.form[formName].values,
  );
  const { parent: parentId, pathname } = values;
  const libelle = values[name];

  const handleBlur = ({ value }: { value?: string }) => {
    if (
      value &&
      (!pathname ||
        ![`${urlType}/${slug(value)}`, slug(value)].includes(pathname))
    ) {
      if (pathname) {
        setUpdateAvailable(true);
      } else {
        UrlData.clear(value, urlType, docId, { boutique, parentId })
          .then(url =>
            dispatch(change(formName, 'pathname', url.get('pathname'))),
          )
          .catch(error => {
            throw new Error(`Url.clear : ${error.message}`);
          });
      }
    }
  };

  const handleUpdatePathnameOnCick = () => {
    setUpdateAvailable(false);
    UrlData.clear(libelle, urlType, docId, { boutique, parentId })
      .then(url => dispatch(change(formName, 'pathname', url.get('pathname'))))
      .catch(error => {
        throw new Error(`Url.clear : ${error.message}`);
      });
  };

  return (
    <DataField
      {...props}
      componentType="input"
      customAction={
        updateAvailable ? (
          <Button
            iconLeft={IconSave}
            onClick={handleUpdatePathnameOnCick}
            status="field-action"
          />
        ) : undefined
      }
      fieldProps={{
        className: classnames('w-full flex-1', {
          'border-r-0 rounded-r-none': updateAvailable,
        }),
      }}
      handleOnBlur={handleBlur}
      name={name}
    />
  );
};

export default Libelle;
