import { BoutiqueData, FirebaseFirestore, ProduitData } from '@innedit/innedit';
import { Link } from 'gatsby';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';

import { BullePhotoEl, BulleProductEl, LigneEl } from './styles';

const ProductMessage: FC<{
  boutique?: FirebaseFirestore.DocumentSnapshot;
  id: string;
  requestType: 'price' | 'reservation';
  user?: boolean;
}> = ({ boutique, id, user = true }) => {
  const [produit, setProduit] =
    useState<firebase.default.firestore.DocumentData>();

  useEffect(() => {
    const produitModel = new ProduitData({ boutique });
    const unsub = produitModel.watchById(id, snapshot => {
      setProduit(snapshot.data());
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique, id]);

  if (!produit) {
    return null;
  }

  const salePrice = produit.salePrice > 0 ? produit.salePrice : produit.price;
  const retailPrice =
    produit.retailPrice > 0 ? produit.retailPrice : produit.price;

  return (
    <LigneEl isUser={user}>
      <BulleProductEl isUser={user}>
        {produit.photos && (
          <BullePhotoEl
            alt={produit.name}
            src={`${BoutiqueData.getDomainImagesURL(boutique)}/h_640,w_640/${
              produit.photos[0]
            }`}
          />
        )}
        <div>
          <h2>
            <Link to={`/boutique/produits/${id}`}>{produit.name}</Link>
          </h2>
          <ul>
            {produit && (
              <>
                {!produit.onSale && <li>Vendu</li>}
                <li>
                  <span>{`${salePrice} €`}</span>
                  {produit.salePrice ||
                    (produit.retailPrice && <span>{`${retailPrice} €`}</span>)}
                </li>

                {(produit.depth || produit.width || produit.height) && (
                  <li
                    dangerouslySetInnerHTML={{
                      __html: `${produit.depth} x ${produit.width}${
                        produit.height
                          ? ` sur ${produit.height} cm (
                      ${
                        Math.ceil(
                          (produit.height * produit.width * produit.depth) /
                            100000,
                        ) / 10
                      } m<sup>3</sup>)`
                          : ''
                      }`,
                    }}
                  />
                )}

                {produit.onSale && (
                  <>
                    {produit.hasInventory &&
                      parseInt(produit.qtyAvailable, 10) > 0 && (
                        <li className="stock">
                          {`${produit.qtyAvailable} pièce${
                            produit.qtyAvailable > 1 ? 's' : ''
                          } en stock`}
                        </li>
                      )}
                    {produit.hasInventory &&
                      parseInt(produit.qtyAvailable, 10) <= 0 && (
                        <li className="stock">
                          <span className="rupture-stock">
                            En rupture de stock
                          </span>
                        </li>
                      )}

                    {produit.min > 1 && (
                      <li>Quantité minimale : {produit.min}</li>
                    )}
                    {'showPrice' !== produit.optionPrice && (
                      <li>Prix sur demande</li>
                    )}
                  </>
                )}

                <li>
                  créé le{' '}
                  {moment(produit.createdAt).format('dddd Do MMMM YYYY')}
                </li>
              </>
            )}
          </ul>
        </div>
      </BulleProductEl>
    </LigneEl>
  );
};

export default ProductMessage;
