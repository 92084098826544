import { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { MODAL_ID } from '../../constants';

const Portal: FC = ({ children }) => {
  const el = document.createElement('div');

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    const modalRoot = document.getElementById(MODAL_ID);

    if (modalRoot) {
      modalRoot.appendChild(el);
    }

    return () => {
      if (modalRoot) {
        modalRoot.removeChild(el);
      }
    };
  }, [el]);

  return ReactDOM.createPortal(children, el);
};

export default Portal;
