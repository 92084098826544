import React, { FC, SyntheticEvent } from 'react';

import StyledField from '../styles/Field';

const Input: FC<{
  className?: string;
  description?: string;
  hideLabel?: boolean;
  label?: string;
  name: string;
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  value?: string | number | boolean;
}> = ({
  className,
  description,
  hideLabel = false,
  label,
  name,
  onChange,
  placeholder,
  type,
  value,
  ...others
}) => {
  switch (type) {
    case 'checkbox':
      return (
        <StyledField className={className}>
          <div className="checkbox">
            <input
              checked={Boolean(value) || false}
              name={name}
              onChange={onChange}
              placeholder={placeholder}
              type="checkbox"
              {...others}
            />
            <span>{description}</span>
          </div>
          <div className={`label ${hideLabel ? 'hide' : 'show'}`}>
            <span>{label}</span>
          </div>
        </StyledField>
      );

    default:
      return (
        <StyledField className={className}>
          <input
            name={name}
            onChange={onChange}
            type={type}
            value={String(value)}
            {...others}
          />
          <div className={`label ${hideLabel ? 'hide' : 'show'}`}>
            <span>{label}</span>
          </div>
        </StyledField>
      );
  }
};

export default Input;
