import { DataArrayProps, FieldArray, useSelector } from 'dataformjs';
import React, { FC, useEffect, useState } from 'react';

import TraductionsRender from './Render';

export interface TraductionsProps extends DataArrayProps {
  boutique: firebase.default.firestore.DocumentSnapshot;
  name: string;
}

const Traductions: FC<TraductionsProps> = props => {
  const { formName, name } = props;
  const value = useSelector((state: any) => state.form[formName]?.values[name]);
  const [traductions, setTraductions] = useState<any[]>();

  useEffect(() => {
    let newTraductions;
    if (value && Object.keys(value).length > 0) {
      newTraductions = Object.keys(value).map(key => ({
        key,
        ...value[key],
      }));
    }

    setTraductions(newTraductions);
  }, [value]);

  // const handleAddItem = (e: SyntheticEvent<HTMLButtonElement>) => {
  //   e.preventDefault();
  //   const newTraductions = traductions || [];
  //   newTraductions.push({
  //     key: '',
  //   });
  //
  //   updateValue(newTraductions);
  //   setTraductions(newTraductions);
  // };

  // const handleChangeItem = (
  //   e: SyntheticEvent<HTMLSelectElement>,
  //   index: number,
  // ) => {
  //   e.preventDefault();
  //   const { value: key } = e.currentTarget;
  //   const newTraductions = traductions;
  //   if (newTraductions) {
  //     newTraductions[index].key = key;
  //   }
  //
  //   updateValue(newTraductions);
  //   setTraductions(newTraductions);
  // };

  // const handleRemoveItem = (
  //   e: SyntheticEvent<HTMLButtonElement>,
  //   index: number,
  // ) => {
  //   e.preventDefault();
  //   const newTraductions = traductions;
  //   if (newTraductions) {
  //     newTraductions.splice(index, 1);
  //   }
  //
  //   updateValue(newTraductions);
  //   setTraductions(newTraductions);
  // };

  // const updateValue = (values?: any[]) => {
  //   let newTraductions = '';
  //   if (values) {
  //     newTraductions = values.reduce((cumul, traduction) => {
  //       const newCumul = cumul;
  //
  //       if (undefined !== traduction.key) {
  //         const copie = { ...traduction };
  //         delete copie.key;
  //         newCumul[traduction.key] = copie;
  //       }
  //
  //       return newCumul;
  //     }, {});
  //   }
  //
  //   dispatch(change(formName, name, newTraductions));
  // };

  const langues: { disabled?: boolean; label: string; value: string }[] = [
    { disabled: false, label: 'Anglais', value: 'en' },
    {
      label: 'Espagnol',
      value: 'es',
    },
    {
      label: 'Allemand',
      value: 'de',
    },
    {
      label: 'Italien',
      value: 'it',
    },
  ];

  let nbEnable = langues ? langues.length : 0;
  traductions?.forEach(traduction => {
    const index = langues.findIndex(l => l.value === traduction.key);
    if (langues && undefined !== index && langues[index]) {
      langues[index].disabled = true;
      nbEnable -= 1;
    }
  });

  return (
    <FieldArray
      component={TraductionsRender}
      name={name}
      props={{ ...props, nbEnable, options: langues }}
    />
  );
};

export default Traductions;
