import { OrderType } from '@innedit/innedit';
import { connect } from 'dataformjs';
import React, { FC } from 'react';

import StyledResume from '../../styles/Resume';

interface ResumeProps {
  boutique?: firebase.default.firestore.DocumentSnapshot;
  formName: string;
  item?: OrderType;
}
const Resume: FC<ResumeProps> = ({ item }) => {
  if (item && item.produits) {
    const {
      amountAashed,
      amountAashedProducts,
      amountAashedDelivery,
      amountRefunded,
      deliveryCost,
      inneditPayInFees,
      stripePayInFees,
      paymentLastError,
      paymentLastErrorCode,
      paymentNextAction,
      paymentNextActionType,
      paymentStatus,
      subTotal,
      total,
    } = item;
    let livraison = 0;

    if (item.deliveryCost) {
      livraison = item.deliveryCost;
    }

    let paymentStatusLabel: string;
    switch (paymentStatus) {
      case 'canceled':
        paymentStatusLabel = 'Annulée';
        break;

      default:
        paymentStatusLabel = paymentStatus;
    }

    return (
      <StyledResume>
        <div className="analytics">
          <div className="total">
            <span className="label">Total</span>
            {amountAashed ? (
              <span>{amountAashed}€</span>
            ) : (
              <span>{total}€</span>
            )}
          </div>
          <div className="infos">
            <h2>Détails</h2>
            <div className="datas">
              <div className="produits">
                {amountAashedProducts ? (
                  <span>{amountAashedProducts}€</span>
                ) : (
                  <span>{subTotal}€</span>
                )}
                <span className="label">Produits</span>
              </div>
              {livraison > 0 && (
                <div>
                  {amountAashedDelivery ? (
                    <span>{amountAashedDelivery}€</span>
                  ) : (
                    <span>{deliveryCost}€</span>
                  )}
                  <span className="label">
                    Livraison {!!item.isPaidToCarrier && '(au transporteur)'}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="paiement">
            <h2>Paiement</h2>
            <div className="datas">
              {'succeeded' !== paymentStatus && (
                <div className="solde">
                  <span>{total} €</span>
                  <div className="label flex space-x-3">
                    <span>{paymentStatusLabel}</span>
                    {paymentLastError &&
                      ['payment_intent_authentication_failure'].includes(
                        paymentLastError,
                      ) && <span>échec de l&apos;authentification</span>}
                    {paymentLastError &&
                      !['payment_intent_authentication_failure'].includes(
                        paymentLastError,
                      ) && <span>{paymentLastError}</span>}
                    {paymentLastErrorCode && (
                      <span>{paymentLastErrorCode}</span>
                    )}

                    {paymentNextAction &&
                      paymentNextActionType &&
                      [
                        'three_d_secure_redirect',
                        'stripe_3ds2_fingerprint',
                      ].includes(paymentNextActionType) && (
                        <span>3D Secure</span>
                      )}
                    {paymentNextAction &&
                      paymentNextActionType &&
                      ![
                        'three_d_secure_redirect',
                        'stripe_3ds2_fingerprint',
                      ].includes(paymentNextActionType) && (
                        <span>{paymentNextActionType}</span>
                      )}

                    {paymentNextAction && !paymentNextActionType && (
                      <span>{paymentNextAction}</span>
                    )}
                  </div>
                </div>
              )}
              {'succeeded' === paymentStatus &&
                undefined !== amountAashed &&
                0 === amountAashed && (
                  <div className="solde">
                    <span>{total} €</span>
                    <span className="label">Pré-paiement</span>
                  </div>
                )}
              {undefined !== amountAashed && amountAashed > 0 && (
                <div>
                  <span>{amountAashed} €</span>
                  <span className="label">Débit</span>
                </div>
              )}
              {undefined !== amountAashed &&
                undefined !== stripePayInFees &&
                stripePayInFees > 0 && (
                  <div>
                    <span>{stripePayInFees} €</span>
                    <span className="label">
                      Frais (
                      {Math.round((stripePayInFees / amountAashed) * 10000) /
                        100}
                      %)
                    </span>
                  </div>
                )}

              {undefined !== amountRefunded && amountRefunded > 0 && (
                <div className="refunded">
                  <span>{amountRefunded} €</span>
                  <span className="label">Remboursement</span>
                </div>
              )}
            </div>
          </div>

          {undefined !== amountAashed &&
            undefined !== inneditPayInFees &&
            inneditPayInFees > 0 && (
              <div className="transaction">
                <h2>Gestion</h2>
                <div className="datas">
                  <div>
                    <span>{inneditPayInFees} €</span>
                    <span className="label">
                      Frais (
                      {Math.round((inneditPayInFees / amountAashed) * 10000) /
                        100}
                      %)
                    </span>
                  </div>
                </div>
              </div>
            )}
        </div>
        <div />
      </StyledResume>
    );
  }

  return null;
};

const mapStateToProps = (globalState: any, ownProps: ResumeProps) => ({
  item: globalState.form[ownProps.formName].values,
});

export default connect(mapStateToProps)(Resume);
