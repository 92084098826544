import { BoutiqueData } from '@innedit/innedit';
import { convertFromRaw, Editor, EditorState } from 'draft-js';
import objectHash from 'object-hash';
import React, { FC, SyntheticEvent } from 'react';
import slug from 'slug';

import Button from '../../../../../../../components/Button';
import { decorator } from '../../../../../../../components/Draft/utils';
import IconArrowDown from '../../../../../../../icons/ArrowDown';
import IconArrowUp from '../../../../../../../icons/ArrowUp';
import IconDelete from '../../../../../../../icons/Delete';
import IconEdit from '../../../../../../../icons/Edit';
import Produit from '../../../../../../Boutique/containers/Produit/components/Crud/Item';
import { Item as ItemSC } from './styles';
import { HeaderType } from './utils';

const Item: FC<{
  boutique?: firebase.default.firestore.DocumentSnapshot;
  deleteOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  document: firebase.default.firestore.DocumentSnapshot;
  downOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  editOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  index: number;
  nbContent: number;
  upOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({
  boutique,
  deleteOnClick,
  document,
  downOnClick,
  editOnClick,
  index,
  nbContent,
  upOnClick,
}) => {
  const classNames = [];
  if (
    document.get('mediasPosition') &&
    document.get('medias') &&
    document.get('medias').length > 0
  ) {
    classNames.push(`medias_${document.get('mediasPosition')}`);
  }
  if (
    document.get('produitsPosition') &&
    document.get('produits') &&
    document.get('produits').length > 0
  ) {
    classNames.push(`produits_${document.get('produitsPosition')}`);
  }

  return (
    <div className="flex my-3">
      <div className="infos flex-1 mr-6">
        {document.get('header') && (
          <HeaderType
            alignement={document.get('headerAlignement')}
            type={document.get('headerType')}
          >
            {document.get('header')}
          </HeaderType>
        )}
        <ItemSC className={classNames.join(' ')}>
          {document.get('medias') && document.get('medias').length > 0 && (
            <div
              className={`medias mediasNbColumns-${
                document.get('mediasNbColumns') || 'na'
              }`}
            >
              {document
                .get('medias')
                .map((photo: { title?: string; fullPath: string }) => (
                  <img
                    key={objectHash(photo)}
                    alt={photo.title ? photo.title : photo.fullPath}
                    src={`${BoutiqueData.getDomainImagesURL(boutique)}/${
                      photo.fullPath
                    }?${document.get('mediasOptions') || 'w=320&h=320&c=max'}`}
                  />
                ))}
            </div>
          )}
          {document.get('produits') && document.get('produits').length > 0 && (
            <div
              className={`produits produitsNbColumns-${
                document.get('produitsNbColumns')
                  ? slug(document.get('produitsNbColumns'))
                  : 'na'
              }`}
            >
              {document.get('produits').map((produitId: string) => (
                <Produit
                  key={produitId}
                  boutique={boutique}
                  itemId={produitId}
                  mode="show"
                  showName={document.get('produitsShowName')}
                />
              ))}
            </div>
          )}
          {document.get('text') && (
            <div className="text">
              <Editor
                editorState={EditorState.createWithContent(
                  convertFromRaw(document.get('text')),
                  decorator,
                )}
                onChange={(): void => {
                  console.info('onChange');
                }}
                readOnly
              />
            </div>
          )}
        </ItemSC>
      </div>
      <div className="flex space-x-1 self-start">
        <Button data-index={index} iconLeft={IconEdit} onClick={editOnClick} />
        {index > 0 && (
          <Button
            data-index={index}
            iconLeft={IconArrowUp}
            onClick={upOnClick}
            size="sm"
            status="secondary"
          />
        )}
        {index < nbContent - 1 && (
          <Button
            data-index={index}
            iconLeft={IconArrowDown}
            onClick={downOnClick}
            size="sm"
            status="secondary"
          />
        )}
        <Button
          data-index={index}
          iconLeft={IconDelete}
          onClick={deleteOnClick}
          size="sm"
          status="delete-action"
        />
      </div>
    </div>
  );
};

export default Item;
