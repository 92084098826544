import { rem } from 'polished';
import styled from 'styled-components';

import down from '../../images/down.svg';
import control from '../../styles/control';
import spacing from '../../styles/spacing';
import { ControlSC } from '../../utils/getControlStyle';

export const SelectSC = styled(ControlSC).attrs({ as: 'select' })`
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;

  padding-right: ${spacing.xl};
  min-width: max-content;

  background-image: url(${down});
  background-repeat: no-repeat, repeat;
  background-position: right ${control.padding.x.base} top 50%, 0 0;
  background-size: ${rem(12)} auto, 100%;
  option {
    font-weight: normal;
  }
`;

export default SelectSC;
