import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Item = styled.div`
  &.medias_above-center,
  &.produits_above-center {
    display: flex;
    flex-direction: column;

    .medias,
    .produits {
      max-width: 100%;
      align-self: center;
    }
  }

  &.medias_below-center,
  &.produits_below-center {
    display: flex;
    flex-direction: column-reverse;

    .medias,
    .produits {
      margin-top: 1.5rem;
      max-width: 100%;
      align-self: center;
    }
  }

  &.medias_beside-text-left,
  &.produits_beside-text-left {
    .medias,
    .produits {
      float: left;
      max-width: 50%;
      margin-right: 1.5rem;
    }
  }

  &.medias_beside-text-right,
  &.produits_beside-text-right {
    .medias,
    .produits {
      float: right;
      max-width: 50%;
      margin-left: 1.5rem;
    }
  }

  &.medias_beside-text-left-centered,
  &.produits_beside-text-left-centered {
    display: flex;
    flex-direction: row;
    .medias,
    .produits {
      margin-right: 1.5rem;
      max-width: 50%;
    }
  }

  &.medias_beside-text-right-centered,
  &.produits_beside-text-right-centered {
    display: flex;
    flex-direction: row-reverse;
    .medias,
    .produits {
      margin-left: 1.5rem;
      max-width: 50%;
    }
  }

  .medias,
  .produits {
    align-content: start;
    display: grid;
    grid-gap: 0.75rem;

    &.mediasNbColumns-na,
    &.produitsNbColumns-na,
    &.mediasNbColumns-1,
    &.produitssNbColumns-1 {
      grid-template-columns: auto;
      grid-template-rows: auto;
    }
    &.mediasNbColumns-2,
    &.produitsNbColumns-2 {
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
    }
    &.mediasNbColumns-3,
    &.produitsNbColumns-3 {
      grid-template-columns: auto auto auto;
      grid-template-rows: auto auto auto;
    }
    &.mediasNbColumns-4,
    &.produitsNbColumns-4 {
      grid-template-columns: auto auto auto auto;
      grid-template-rows: auto auto auto auto;
    }
    &.mediasNbColumns-5,
    &.produitsNbColumns-5 {
      grid-template-columns: auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto;
    }
    &.mediasNbColumns-6,
    &.produitsNbColumns-6 {
      grid-template-columns: auto auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto auto;
    }
  }

  .public-DraftStyleDefault-block {
    margin-bottom: 1.225rem;
  }
`;
