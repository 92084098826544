import objectHash from 'object-hash';
import React, { FC, SyntheticEvent, useState } from 'react';

import Button, { ButtonProps } from '../../../components/Button';
import IconClose from '../../../icons/Close';
import { SearchProps } from '../containers/Boutique/components/new/props';
import ActionBar from './ActionBar';

interface NavBarItemProps {
  className?: string;
  type: string;
  options?: {
    value: any;
    label: string;
  }[];
  [key: string]: any;
}

export type MenuItemProp = NavBarItemProps | ButtonProps;

export interface MenuProp {
  left: MenuItemProp[];
  right: MenuItemProp[];
}

const NavBar: FC<{
  menu: MenuItemProp[];
}> = ({ menu }) => {
  if (!menu || 0 === menu.length) {
    return null;
  }

  return (
    <ul className="flex space-x-2">
      {menu.map((navItem, index) => {
        switch (navItem.type) {
          // case 'filter':
          //   return (
          //     <li key={objectHash(navItem)} className="navbar-item filter">
          //       Filtre :
          //       {navItem.buttons &&
          //         navItem.buttons.length > 0 &&
          //         navItem.buttons.map(button => (
          //           <Button
          //             key={objectHash(button)}
          //             {...button}
          //             onClick={e => navItem.onClick(e, button.wheres)}
          //           />
          //         ))}
          //     </li>
          //   );

          // case "feature":
          //   switch (navItem.components) {
          //     case "select":
          //     default:
          //       return (
          //         <li key={index} className="navbar-item feature">
          //           <DataFeatureSelect {...navItem}/>
          //         </li>
          //       );
          //   }
          //

          case 'input': {
            const { className, ...props } = navItem as NavBarItemProps;

            return (
              <li
                key={objectHash({ ...navItem, index })}
                className="navbar-item input"
              >
                <input
                  className={
                    className ||
                    'relative leading-10 h-10 px-3 border border-light-600 rounded'
                  }
                  {...props}
                />
              </li>
            );
          }

          case 'select': {
            const { className, options, placeholder, ...props } =
              navItem as NavBarItemProps;

            return (
              <li
                key={objectHash({ ...navItem, index })}
                className="navbar-item search"
              >
                <select
                  className={
                    className ||
                    'relative leading-10 h-10 px-3 border border-light-600 rounded'
                  }
                  {...props}
                >
                  <option value="">{placeholder || '--'}</option>
                  {options &&
                    options.map(option => (
                      <option
                        key={objectHash({ ...navItem, index, ...option })}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                </select>
              </li>
            );
          }

          default:
        }

        return (
          <li key={objectHash(navItem)}>
            <Button {...(navItem as ButtonProps)} />
          </li>
        );
      })}
    </ul>
  );
};

const SearchCmp: FC<SearchProps> = ({
  className,
  clearOnclick,
  defaultValue = '',
  ...props
}) => {
  const [q, setQ] = useState(defaultValue);
  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setQ(value);
  };

  const handleClearOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    setQ('');
    if (clearOnclick) {
      clearOnclick(event);
    }
  };

  return (
    <ul className="flex-1">
      <li className="flex navbar-item search relative">
        <input
          {...props}
          className={`!pr-6 flex-1 ${
            className ||
            'relative leading-10 h-10 px-3 border border-light-600 border-box rounded'
          }`}
          onChange={handleOnChange}
          type="text"
          value={q}
        />
        {q && clearOnclick && (
          <Button
            className="absolute right-3"
            iconLeft={IconClose}
            onClick={handleClearOnClick}
            status="link"
          />
        )}
      </li>
    </ul>
  );
};
const Menu: FC<{
  menu?: MenuProp;
  search?: SearchProps;
}> = ({ menu, search }) => {
  if (!menu || 0 === Object.keys(menu).length) {
    return null;
  }

  return (
    <ActionBar className="action-bar is-clearfix">
      {menu.left && <NavBar menu={menu.left} />}
      <div className="flex flex-row space-x-2 flex-1">
        {search && <SearchCmp {...search} />}
        {menu.right && <NavBar menu={menu.right} />}
      </div>
    </ActionBar>
  );
};

export default Menu;
