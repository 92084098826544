import React, { FC, SyntheticEvent, useState } from 'react';

import Button from '../Button';
import { Options as OptionsSC, Response as ResponseSC } from './styles';
import AutoTextarea from './Textarea';

const Response: FC<{
  onClickMarkAsAnswered?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  onSubmit: (message: string) => void;
}> = ({ onClickMarkAsAnswered, onSubmit }) => {
  const [value, setValue] = useState<string>();
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const handleOnChange = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.value);
  };

  const handleOnSubmit = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (value) {
      onSubmit(value);
    }

    setValue('');
  };

  const handleToggleOptions = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowOptions(!showOptions);
  };

  return (
    <div>
      <ResponseSC>
        <button
          className="show-options"
          onClick={handleToggleOptions}
          type="submit"
        >
          Options
        </button>
        <AutoTextarea onChange={handleOnChange} value={value} />
        <button className="send" onClick={handleOnSubmit} type="submit">
          Envoyer
        </button>
      </ResponseSC>
      {showOptions && (
        <OptionsSC>
          {onClickMarkAsAnswered && (
            <Button
              onClick={onClickMarkAsAnswered}
              text="Marquer comme répondue"
            />
          )}
        </OptionsSC>
      )}
    </div>
  );
};

export default Response;
