import { DataField } from 'dataformjs';
import React, { FC, SyntheticEvent, useState } from 'react';

import Button from '../../../../../../../components/Button';
import IconDelete from '../../../../../../../icons/Delete';
import IconExchangeAlt from '../../../../../../../icons/ExchangeAlt';

const Specifique: FC<{
  data: any;
  handleRemove: (event: SyntheticEvent<HTMLButtonElement>) => void;
  name: string;
}> = ({ data, handleRemove, name }) => {
  const [editLibelle, setEditLibelle] = useState<boolean>(!data.libelle);

  const handleSwapOnClick = () => {
    setEditLibelle(old => !old);
  };

  return (
    <div className="flex space-x-1 mb-3">
      {editLibelle ? (
        <DataField
          componentType="input"
          label="Quel est le nom de la dimension ?"
          name={`${name}.libelle`}
          type="text"
          wrapperProps={{ className: 'flex-1 mb-0' }}
        />
      ) : (
        <DataField
          componentType="input"
          label={data.libelle}
          name={`${name}.valeur`}
          type="text"
          wrapperProps={{ className: 'flex-1 mb-0' }}
        />
      )}
      <div className="flex space-x-1 items-end">
        <Button
          iconLeft={IconExchangeAlt}
          onClick={handleSwapOnClick}
          status="secondary"
        />
        <Button
          iconLeft={IconDelete}
          onClick={handleRemove}
          size="sm"
          status="delete-action"
        />
      </div>
    </div>
  );
};

export default Specifique;
