import { ContentData } from '@innedit/innedit';
import { DataProps } from 'dataformjs';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import HOCGroup from '../../../../../components/HOC/Group';
import Content from './Content';
import Item from './Item';

const List: FC<
  DataProps & {
    addText?: string;
    boutique?: firebase.default.firestore.DocumentSnapshot;
    docId: string;
    docRef: firebase.default.firestore.DocumentReference;
    title?: string;
  }
> = ({ addText, boutique, docId, docRef, title }) => {
  const [contents, setContents] =
    useState<firebase.default.firestore.QueryDocumentSnapshot[]>();
  const [index, setIndex] = useState<number>();

  useEffect(() => {
    const unsub: () => void = docRef
      .collection('contents')
      .orderBy('order', 'asc')
      .onSnapshot(querySnapshot => {
        setContents(querySnapshot.docs);
      });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docRef]);

  const handleAddOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    ContentData.insertIntoDocument(docRef, {
      header: '',
      headerAlignement: '',
      headerDate: '',
      headerLink: '',
      headerSubTitle: '',
      headerType: '',
      medias: [],
      mediasClickToEnlarge: false,
      mediasNbColumns: 1,
      mediasPosition: 'beside-text-left',
      order: contents ? contents.length : 0,
    });
  };

  const handleEditOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );
    if (Number.isInteger(i)) {
      setIndex(i);
    }
  };

  const handleUpOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );
    if (index && contents && i > 0) {
      // On inverse le index et le index-1
      const contentMinus = contents[index - 1];
      contents[i].ref.update({
        order: i - 1,
      });
      contentMinus.ref.update({ order: i });
    }
  };

  const handleDownOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );
    if (contents && i < contents.length - 1) {
      // On inverse le index et le index-1
      const contentPlus = contents[i + 1];
      contents[i].ref.update({
        order: i + 1,
      });
      contentPlus.ref.update({ order: i });
    }
  };

  const handleCloseOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setIndex(undefined);
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );

    if (
      Number.isInteger(i) &&
      contents &&
      window.confirm('Confirmation de la suppression du contenu')
    ) {
      const document = contents[i];
      ContentData.delete(document);
    }
  };

  // const handleOnBlur = (event: SyntheticEvent<HTMLInputElement>) => {
  //   console.info('handleOnBlur', index);
  //   event.preventDefault();
  //   if (contents && undefined !== index) {
  //     let value: string | number | boolean;
  //     const { name } = event.currentTarget;
  //
  //     switch (event.currentTarget.type) {
  //       case 'number':
  //         value = parseInt(event.currentTarget.value, 10);
  //         break;
  //
  //       case 'checkbox':
  //         value = event.currentTarget.checked;
  //         break;
  //
  //       case 'text':
  //       default:
  //         value = event.currentTarget.value;
  //     }
  //
  //     contents[index].ref.update({
  //       [name]: value,
  //     });
  //   }
  // };

  const handleOnChangeField = (field: string, value: any) => {
    if (contents && index !== undefined) {
      ContentData.update(contents[index], {
        [field]: value,
      });
    }
  };

  return (
    <HOCGroup addOnClick={handleAddOnClick} addText={addText} title={title}>
      {contents && index !== undefined && (
        <Content
          boutique={boutique}
          closeOnClick={handleCloseOnClick}
          docId={docId}
          // handleOnBlur={handleOnBlur}
          item={contents[index]}
          onChange={handleOnChangeField}
        />
      )}
      {!contents && (
        <p style={{ marginBottom: 0, padding: '1.5rem' }}>
          Chargement en cours
        </p>
      )}

      {contents && 0 === contents.length && (
        <p style={{ marginBottom: 0, padding: '1.5rem' }}>Aucun contenu</p>
      )}

      {contents && 0 < contents.length && (
        <ul className="mx-6 mt-6 mb-3">
          {contents.map((content, idx) => (
            <Item
              key={content.id}
              boutique={boutique}
              deleteOnClick={handleDeleteOnClick}
              document={content}
              downOnClick={handleDownOnClick}
              editOnClick={handleEditOnClick}
              index={idx}
              nbContent={contents.length}
              upOnClick={handleUpOnClick}
            />
          ))}
        </ul>
      )}
    </HOCGroup>
  );
};

export default List;
