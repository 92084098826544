import { FeatureData } from '@innedit/innedit';
import { DataProps, Field, FormSection } from 'dataformjs';
import React, { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import FieldSC from '../../../../styles/Field';

interface MultiSelectProps extends DataProps {
  boutique: firebase.default.firestore.DocumentSnapshot;
  collectionName: string;
  name: string;
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
}
const MultiSelect: FC<MultiSelectProps> = ({
  boutique,
  className,
  collectionName,
  label,
  name,
  orderDirection,
  orderField,
}) => {
  const [options, setOptions] =
    useState<firebase.default.firestore.QueryDocumentSnapshot[]>();

  useEffect(() => {
    const feature = new FeatureData(boutique, {
      collectionName,
      orderDirection,
      orderField,
    });
    const unsub: () => void = feature.watch(snapshot => {
      setOptions(snapshot.docs);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique, collectionName]);

  if (!options || 0 === options.length) {
    return null;
  }

  const id = uuidv4();

  return (
    <FormSection name={name}>
      <FieldSC className={`cms__field checkbox ${className || ''}`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
          }}
        >
          {options.map(option => (
            <label
              key={`${name}_${option.id}`}
              className="checkbox"
              htmlFor={`${id}_${option.id}`}
            >
              <Field
                component="input"
                id={`${id}_${option.id}`}
                name={option.id}
                type="checkbox"
              />
              <span>{option.get('libelle')}</span>
            </label>
          ))}
        </div>

        <label className="label" htmlFor={id}>
          <span>{label}</span>
        </label>
      </FieldSC>
    </FormSection>
  );
};

export default MultiSelect;
