import { FirebaseFirestore } from '@innedit/innedit';
import { DataProps, FieldArray } from 'dataformjs';
import React, { VoidFunctionComponent } from 'react';

import ProductsRender from './Render';

export interface ProduitsProps extends DataProps {
  boutique: FirebaseFirestore.DocumentSnapshot;
  docId: string;
  formName: string;
  formValues: { [key: string]: any };
}

const Produits: VoidFunctionComponent<ProduitsProps & { name: string }> = ({
  name,
  ...props
}) => <FieldArray component={ProductsRender} name={name} props={props} />;

export default Produits;
