import styled from 'styled-components';

const ItemSC = styled.li`
  width: 100%;
  display: flex;

  align-items: flex-start;

  box-sizing: border-box;
  margin-bottom: 0.75rem;

  img {
    height: 60px;
    margin-right: 0.75rem;
  }

  input {
    margin-right: 0.75rem;
  }

  .infos {
    flex: 1;

    .tarif {
      .remise {
        font-style: italic;
        font-size: 12px;
      }
      span {
        display: inline-block;
      }
      span + span {
        margin-left: 0.75rem;
      }
    }
  }

  .account-connected {
    margin-left: 0.75rem;
    font-size: 80%;
  }

  .account-connected--amount-aashed {
    margin-left: 0.75rem;
    font-size: 80%;
  }

  .prix {
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
    }
  }
`;

export default ItemSC;
