import { DataProps } from 'dataformjs';
import React, { FC } from 'react';

import Boutique from '../../../Boutique/components/Data';
import Website from '../../../Website/components/Data';
import Content from '../../containers/Content/components/Data';
import Feature from '../../containers/Feature/components/Data';

const Data: FC<
  DataProps & {
    formName: string;
    type?: string;
  }
> = props => {
  const { componentType, type, params } = props;

  switch (componentType) {
    case 'boutique': {
      if (!type) {
        return <div>boutique : erreur de paramètre : type est obligatoire</div>;
      }

      // if (!params || !params.boutique) {
      //   return (
      //     <div>boutique : erreur de paramètre : boutique est obligatoire</div>
      //   );
      // }

      return <Boutique {...props} boutique={params?.boutique} type={type} />;
    }

    case 'content': {
      if (!type) {
        return <div>content : erreur de paramètre : type est obligatoire</div>;
      }

      return <Content {...props} type={type} />;
    }

    case 'feature': {
      if (!type) {
        return <div>website : erreur de paramètre : type est obligatoire</div>;
      }

      return <Feature {...props} boutique={params?.boutique} type={type} />;
    }

    case 'website': {
      if (!type) {
        return <div>website : erreur de paramètre : type est obligatoire</div>;
      }

      return <Website {...props} boutique={params?.boutique} type={type} />;
    }

    default:
  }

  return null;
};

export default Data;
