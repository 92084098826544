import { NOTIFICATION_CREATE, NOTIFICATION_REMOVE } from '../../actionTypes';
// import firestore from '../../../../api/firestore';

/**
 * remove
 *
 * @param notification
 * @returns {AnyAction}
 */
export function create(notification) {
  return {
    notification,
    type: NOTIFICATION_CREATE,
  };
}

/**
 * remove
 *
 * @param key
 * @returns {AnyAction}
 */
export function remove(key) {
  return {
    key,
    type: NOTIFICATION_REMOVE,
  };
}
