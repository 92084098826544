import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconCard from '../../icons/Card';
import Tooltip from '../../sysext/CMS/components/Tooltip';
import Icon from '../Icon';
import { ButtonProps } from './index';
import ButtonSC from './style';

const ButtonTooltip: FC<ButtonProps> = ({
  children,
  className,
  clipboard,
  text,
  type,
  value,
  ...others
}) => {
  const tooltipRef = useRef<HTMLButtonElement>(null);
  const [showToolTip, setToolTip] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const show = () => {
      setToolTip(true);
    };

    const hide = () => {
      setToolTip(false);
    };

    const clean = () => {
      if (tooltipRef && tooltipRef.current) {
        tooltipRef.current.removeEventListener('mouseover', show);
        tooltipRef.current.removeEventListener('mouseout', hide);
      }
    };

    if (tooltipRef && tooltipRef.current) {
      tooltipRef.current.addEventListener('mouseover', show);
      tooltipRef.current.addEventListener('mouseout', hide);
    }

    return () => {
      clean();
    };
  }, []);

  const onClick = (e: SyntheticEvent<HTMLElement>) => {
    if (clipboard && value) {
      e.preventDefault();
      if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText(value);
      }
    }
  };

  return (
    <ButtonSC
      ref={tooltipRef}
      className={`${className} overflow-visible`}
      data-value={value}
      onClick={onClick}
      {...others}
    >
      {children}
      <Icon value={IconCard} />
      {text && <span className="text">{t(text)}</span>}
      {showToolTip && (
        <Tooltip
          direction="left"
          style={{ right: '50px', top: '6px' }}
          value={value}
        />
      )}
    </ButtonSC>
  );
};

export default ButtonTooltip;
