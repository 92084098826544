import { MarchandData } from '@innedit/innedit';
import { DataProps, Field, FormSection } from 'dataformjs';
import React, { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import FieldSC from '../../../../../CMS/styles/Field';

interface MultiSelectProps extends DataProps {
  boutique?: firebase.default.firestore.DocumentSnapshot;
  name: string;
}

const MultiSelect: FC<MultiSelectProps> = ({
  boutique,
  className,
  label,
  name,
}) => {
  const [options, setOptions] =
    useState<firebase.default.firestore.QueryDocumentSnapshot[]>();

  useEffect(() => {
    let unsub: () => void;
    if (boutique) {
      const marchandModel = new MarchandData({ boutique });
      unsub = marchandModel.watch(
        snapshot => {
          setOptions(snapshot.docs);
        },
        {
          wheres: {
            hidden: false,
          },
        },
      );
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique]);

  if (!options || 0 === options.length) {
    return null;
  }

  const id = uuidv4();

  return (
    <FormSection name={name}>
      <FieldSC className={`cms__field checkbox ${className || ''}`}>
        <div className="flex flex-col justify-start">
          {options.map(option => (
            <label
              key={`${name}_${option.id}`}
              className="checkbox"
              htmlFor={`${id}_${option.id}`}
            >
              <Field
                component="input"
                id={`${id}_${option.id}`}
                name={option.id}
                type="checkbox"
              />
              <span>{option.get('libelle')}</span>
            </label>
          ))}
        </div>

        <label className="label" htmlFor={id}>
          <span>{label}</span>
        </label>
      </FieldSC>
    </FormSection>
  );
};

export default MultiSelect;
