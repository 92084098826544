import { ActionData, BoutiqueData, MediaData } from '@innedit/innedit';
import React, { FC, SyntheticEvent, useRef, useState } from 'react';
import slug from 'slug';

import svg from '../../../../images/dropzone.svg';
import StyledModal from '../../styles/Modal';
import HOCGroup from '../HOC/Group';
import Modal from '../View/Modal';
import Photo from './Item';
import MediasList from './List';
import { List as ListSC } from './styles';

export interface MediaProps {
  description?: string;
  fullPath: string;
  link?: string;
  media?: string;
  title?: string;
}

interface MediasProps {
  addText: string;
  boutique?: firebase.default.firestore.DocumentSnapshot;
  docId: string;
  docType: string;
  handleAddItems: (
    medias: firebase.default.firestore.DocumentSnapshot[],
  ) => void;
  handleChangePosition: (oldIndex: number, newIndex: number) => void;
  handleRemoveItem: (index: number) => void;
  items: MediaProps[];
  title: string;
}

const Medias: FC<MediasProps> = ({
  addText,
  boutique,
  docId,
  docType,
  handleAddItems,
  handleChangePosition,
  handleRemoveItem,
  items,
  title,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [photoIndex, setPhotoIndex] = useState<number>();
  const [previews, setPreviews] = useState<string[]>();
  const [isOpenMedias, setIsOpenMedias] = useState<boolean>();

  const onDrop = (files: FileList) => {
    const promises: any[] = [];
    const tmpPreviews = [];
    for (let i = 0; i < files.length; i += 1) {
      const image = files.item(i);
      if (image) {
        const preview = URL.createObjectURL(image);
        tmpPreviews.push(preview);

        promises.push(
          MediaData.uploadPhoto(image, {
            boutique,
            docId,
            docType,
          }),
        );
      }
    }
    setPreviews(tmpPreviews);

    Promise.all(promises)
      .then(values => {
        const tmpItems: firebase.default.firestore.DocumentSnapshot[] = [];
        values.forEach(document => {
          if (document.exists) {
            tmpItems.push(document);
          } else {
            console.error("Le document n'existe pas");
          }
        });

        handleAddItems(tmpItems);

        return setPreviews([]);
      })
      .catch(error => {
        // TODO afficher le message d'erreur -> Mettre en place un système de notification
        console.error(error.code, error.message);

        return setPreviews([]);
      });
  };

  const handleOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget && e.currentTarget.files) {
      onDrop(e.currentTarget.files);
    }
  };

  const handleOpenBrowser = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleSelectMedias = () => {
    setIsOpenMedias(!isOpenMedias);
  };

  const handleOnShowPhoto = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const index = e.currentTarget.getAttribute('data-index');
    if (index) {
      setPhotoIndex(parseInt(index, 10));
    }

    return false;
  };

  /**
   * Cette methode permet de supprimer une photo du tableau
   *
   * @param {number} index
   */
  const removeItem = (index: number) => {
    console.info(`removeItem ${docType}`, docId);
    if (items[index]) {
      if (items[index].media) {
        ActionData.create(
          'media-delete',
          {
            docId,
            docType,
            id: items[index].media,
          },
          {
            boutique,
          },
        );
      }

      handleRemoveItem(index);
    }
  };

  const handleCloseModal = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setPhotoIndex(undefined);

    return false;
  };

  // <button
  //   className="is-pulled-right"
  //   onClick={handleSelectMedias}
  //   type="button"
  // >
  //   Utiliser une photo existante
  // </button>

  return (
    <HOCGroup addOnClick={handleOpenBrowser} addText={addText} title={title}>
      {isOpenMedias && (
        <Modal
          closeOnClick={handleSelectMedias}
          title="Selectionner des médias"
        >
          <MediasList
            boutique={boutique}
            docId={docId}
            docType="produit"
            handleAddItems={handleAddItems}
          />
        </Modal>
      )}

      <div className="group">
        {previews && previews.length > 0 && (
          <span className="loading-in-progress">Chargement en cours</span>
        )}
        <input
          ref={inputRef}
          accept="image/jpeg"
          className="hidden"
          multiple
          onChange={handleOnChange}
          type="file"
        />
        {(!items || 0 === items.length) &&
          (!previews || 0 === previews.length) && (
            <div className="first-upload">
              <img alt="Upload files" src={svg} />
            </div>
          )}
        {((items && items.length > 0) || (previews && previews.length > 0)) && (
          <div className="w-full outline-none box-border">
            <ListSC>
              {items.map((photo, index) => (
                <Photo
                  key={slug(photo.fullPath)}
                  boutique={boutique}
                  handleChangePosition={handleChangePosition}
                  handleOnShowPhoto={handleOnShowPhoto}
                  handleRemoveItem={removeItem}
                  index={index}
                  photo={photo}
                />
              ))}
              {previews &&
                previews.length > 0 &&
                previews.map(photo => (
                  <li key={photo}>
                    <figure className="aspect-ratio aspect-ratio--square">
                      <img
                        alt="chargement en cours"
                        className="aspect-ratio__content"
                        src={photo}
                        style={{ filter: 'grayscale(1)' }}
                      />
                    </figure>
                  </li>
                ))}
            </ListSC>
          </div>
        )}
      </div>
      {undefined !== photoIndex && (
        <StyledModal>
          <div className="photo">
            <button
              className="photo-close"
              onClick={handleCloseModal}
              type="button"
            >
              <span className="text">Fermer</span>
            </button>
            <div className="photo-content">
              <img
                alt={items[photoIndex].title}
                src={`${BoutiqueData.getDomainImagesURL(boutique)}/${
                  items[photoIndex].fullPath
                }`}
              />
            </div>
          </div>
        </StyledModal>
      )}
    </HOCGroup>
  );
};

export default Medias;
