import { Group } from 'dataformjs';
import React, { FC } from 'react';

import Input from '../../../../../../components/Input';
import Medias from '../../../../../../components/Medias';
import Select from '../../../../../../components/Select';

// {
//   medias: MediaProps[];
//   mediasClickToEnlarge: boolean;
//   mediasNbColumns: number;
//   mediasPosition: string;
//   mediasOptions?: string;
// }

const ImagesTab: FC<{
  boutique?: firebase.default.firestore.DocumentSnapshot;
  data?: firebase.default.firestore.DocumentData;
  docId: string;
  handleOnBlur: () => void;
  onChange: (attr: string, value: any) => void;
}> = ({ data, boutique, docId, handleOnBlur, onChange }) => {
  const options = {
    mediasNbColumns: [
      {
        label: '1',
        value: 1,
      },
      {
        label: '2',
        value: 2,
      },
      {
        label: '3',
        value: 3,
      },
      {
        label: '4',
        value: 4,
      },
      {
        label: '5',
        value: 5,
      },
      {
        label: '6',
        value: 6,
      },
    ],
    mediasPosition: [
      {
        label: 'Centré au dessus',
        value: 'above-center',
      },
      {
        label: 'Centré en dessous',
        value: 'below-center',
      },
      {
        label: 'A droite du texte',
        value: 'beside-text-right',
      },
      {
        label: 'A gauche du texte',
        value: 'beside-text-left',
      },
      {
        label: 'Centré à droite du texte',
        value: 'beside-text-right-centered',
      },
      {
        label: 'Centré à gauche du texte',
        value: 'beside-text-left-centered',
      },
    ],
  };

  const handleAddItems = (
    medias: firebase.default.firestore.DocumentSnapshot[],
  ): void => {
    if (data) {
      const tmp =
        data.medias && data.medias.length > 0 ? data.medias.slice(0) : [];

      if (medias && medias.length > 0) {
        medias.forEach(media => {
          tmp.push({
            description: media.get('description'),
            fullPath: media.get('fullPath'),
            link: '',
            media: media.id,
            title: media.get('title'),
          });
        });
      }

      onChange('medias', tmp);
    }
  };

  const handleChangePosition = (oldIndex: number, newIndex: number): void => {
    if (data && data.medias && data.medias.length > 1) {
      const tmp = data.medias.slice(0);
      const item = { ...data.medias[oldIndex] };
      if (newIndex !== oldIndex) {
        if (newIndex < oldIndex) {
          // La nouvelle position est avant
          // On ajout la nouvelle photo
          // On supprime l'ancien position + 1
          tmp.splice(newIndex, 0, item);
          tmp.splice(oldIndex + 1, 1);
        } else {
          // la nouvelle position est après
          tmp.splice(oldIndex, 1);
          tmp.splice(newIndex, 0, item);
        }
      }

      onChange('medias', tmp);
    }
  };

  const handleRemoveItem = (index: number): void => {
    console.info('handleRemoveItem', index);
    if (data && data.medias && data.medias.length > 0) {
      const tmp = data.medias.slice(0);
      tmp.splice(index, 1);
      onChange('medias', tmp);
    }
  };

  return (
    <div>
      <Medias
        addText="Ajouter une image"
        boutique={boutique}
        docId={docId}
        docType="page"
        handleAddItems={handleAddItems}
        handleChangePosition={handleChangePosition}
        handleRemoveItem={handleRemoveItem}
        items={data && data.medias ? data.medias : []}
        title="Images"
      />
      <Group title="Options">
        <Select
          label="Position et alignement"
          name="mediasPosition"
          onChange={handleOnBlur}
          options={options.mediasPosition}
          value={data && data.mediasPosition}
        />
        <Select
          label="Nombre de colonnes"
          name="mediasNbColumns"
          onChange={handleOnBlur}
          options={options.mediasNbColumns}
          value={data && data.mediasNbColumns}
        />
        <Input
          description="Cliquer pour agrandir"
          label="Agrandir"
          name="mediasClickToEnlarge"
          onChange={handleOnBlur}
          type="checkbox"
          value={data && data.mediasClickToEnlarge}
        />
        <Input
          description="options des images"
          label="Options"
          name="mediasOptions"
          onChange={handleOnBlur}
          type="text"
          value={data && data.mediasOptions}
        />
      </Group>
    </div>
  );
};

export default ImagesTab;
