import { getMediaImageData, ProduitType } from '@innedit/innedit';
import React, { FC } from 'react';

import ImagePhoto from '../../../../../../../images/Photo';
import { colors } from '../../../../../../../styles/theme';

const Item: FC<{ data: ProduitType }> = ({ data }) => {
  const { photos } = data;
  const image =
    photos &&
    getMediaImageData({
      breakpoints: [150, 240, 320, 480, 640],
      maxWidth: 640,
      media: {
        height: 640,
        pathname: photos[0],
        width: 640,
      },
      transformations: ['c_contain', 'b_ffffff', 'r_1'],
    });

  return (
    <li>
      <figure className="w-full relative rounded-t cursor-pointer pb-full">
        <div className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
          <ImagePhoto color={colors.light[600]} size={100} />
        </div>
        {image && (
          <img
            {...image.images}
            alt={data.name}
            className="absolute rounded-t"
          />
        )}
      </figure>

      <h2 className="truncate text-center">{data.name}</h2>
    </li>
  );
};

export default Item;
