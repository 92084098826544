import React, { FC } from 'react';

import { DraftEl } from '../../../../../../../../components/Draft';

const Text: FC<{
  data?: firebase.default.firestore.DocumentData;
  onChange: (attr: string, value: any) => void;
}> = ({ data, onChange }) => (
  <DraftEl
    input={{
      onChange: value => onChange('text', value),
      value: data?.text,
    }}
  />
);

export default Text;
