import styled, { ThemeProps } from 'styled-components';

import { colors, DefaultThemeProps } from '../../styles/theme';

const Tabs = styled.div<ThemeProps<DefaultThemeProps>>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1.5rem;

  display: flex;
  flex-direction: column;

  &.alt {
    background-color: #fbfbfc;
    border: 1px solid rgba(228, 231, 237, 0.4);
  }

  > p {
    margin: 1.5rem;
  }
`;

export const TabsBar = styled.ul``;

export const TabsBarItem = styled.li`
  &.is-active {
    color: ${colors.primary[500]};
    border-bottom: 2px solid ${colors.primary[500]};
  }
`;

export default Tabs;
