import React, { FC } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const HeaderType: FC<{
  type: string;
  alignement?: 'left' | 'center' | 'right';
}> = ({ type, alignement = 'left', children }) => {
  switch (type) {
    case 'h1':
      return <h1 style={{ textAlign: alignement }}>{children}</h1>;

    case 'h3':
      return <h3 style={{ textAlign: alignement }}>{children}</h3>;

    case 'h4':
      return <h4 style={{ textAlign: alignement }}>{children}</h4>;

    case 'h5':
      return <h5 style={{ textAlign: alignement }}>{children}</h5>;

    case 'h6':
      return <h6 style={{ textAlign: alignement }}>{children}</h6>;

    case 'hidden':
      return null;

    case 'h2':
    default:
      return <h2 style={{ textAlign: alignement }}>{children}</h2>;
  }
};
