import { DataField } from 'dataformjs';
import React, { FC, SyntheticEvent } from 'react';

import Button from '../../../../../../../components/Button';
import IconDelete from '../../../../../../../icons/Delete';

const Traduction: FC<{
  data: any;
  formName: string;
  handleRemove: (event: SyntheticEvent<HTMLButtonElement>) => void;
  name: string;
  options: { disabled?: boolean; label: string; value: string }[];
}> = ({ data, formName, handleRemove, name, options }) => (
  <div className="flex space-x-3">
    <DataField
      componentType="select"
      disabled={Object.keys(data).length > 1}
      formName={formName}
      labelShow={false}
      name={`${name}.key`}
      options={options}
      wrapperProps={{ className: 'flex-1 mb-0' }}
    />

    {/* <Select */}
    {/*  className="is-expanded no-margin-bottom" */}
    {/*  label="Langue" */}
    {/*  name="" */}
    {/*  onChange={handleChange} */}
    {/*  options={langues} */}
    {/*  value={data} */}
    {/* /> */}
    <Button iconLeft={IconDelete} onClick={handleRemove} />
  </div>
);

export default Traduction;
