import moment from 'moment';
import React, { FC } from 'react';

import MessageType from '../../sysext/CMS/types/DocumentData/Message';
import ProductMessage from './Product';
import { BulleEl, DateEl, LigneEl, MessageEl } from './styles';

interface MessageProps {
  boutique?: firebase.default.firestore.DocumentSnapshot;
  doc: firebase.default.firestore.DocumentSnapshot<MessageType>;
}
const Message: FC<MessageProps> = ({ boutique, doc }) => {
  if (!doc.exists) {
    return null;
  }
  const data = doc.data();
  if (!data) {
    return null;
  }
  const { createdAt, priceProducts, reservedProducts, text, user } = data;

  return (
    <MessageEl>
      <DateEl>{moment(createdAt).calendar()}</DateEl>
      <LigneEl isUser={user}>
        <BulleEl isUser={user}>
          <p>{text}</p>
        </BulleEl>
      </LigneEl>
      {priceProducts &&
        priceProducts.map(product => (
          <ProductMessage
            key={product}
            boutique={boutique}
            id={product}
            requestType="price"
            user={user}
          />
        ))}
      {reservedProducts &&
        reservedProducts.map(product => (
          <ProductMessage
            key={product}
            boutique={boutique}
            id={product}
            requestType="reservation"
          />
        ))}
    </MessageEl>
  );
};

export default Message;
