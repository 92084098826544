import styled from 'styled-components';

export default styled.div`
  > ul {
    border-bottom: 1px solid #e4e7ed !important;
    font-size: 14px !important;
    height: auto !important;
    line-height: 1.3334 !important;
    margin: 0;
    margin-bottom: 30px !important;
    padding: 0 !important;
    list-style: none;
    display: flex;
    align-items: flex-end;
    flex-wrap: ${props => (props.theme.mobile ? 'nowrap' : 'wrap')};
    overflow-x: ${props => (props.theme.mobile ? 'auto' : 'hidden')};
    overflow-y: hidden;

    li {
      margin-right: 10px;
    }
  }
`;
