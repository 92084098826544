import { DataField, DataFieldProps, useSelector } from 'dataformjs';
import React, { FC } from 'react';

interface SelectProps extends DataFieldProps {
  formName: string;
  name: string;
}

const Select: FC<Omit<SelectProps, 'componentType'>> = props => {
  const boutiques: firebase.default.firestore.QueryDocumentSnapshot[] =
    useSelector((state: any) => state.cms.boutiques);

  return (
    <DataField
      {...props}
      componentType="select"
      options={boutiques.map(boutique => ({
        label: boutique.get('name'),
        value: boutique.id,
      }))}
    />
  );
};

export default Select;
