import React, { FC, useEffect, useRef } from 'react';
// import styled from 'styled-components';
//
// import close from '../../../images/font-awesome/solid/times-circle.svg';

// const Div = styled.div`
//   .menu-annexe {
//     float: right;
//
//     .menu-annexe-content {
//       display: none;
//       position: absolute;
//       padding: 0.375rem;
//       left: 0;
//       right: 0;
//       top: 50px;
//       border: 1px solid #e4e7ed;
//       border-top: none;
//       background: #fbfbfc;
//       z-index: 1000;
//       ul {
//         border: none !important;
//         display: flex;
//         flex-direction: column;
//         width: 100%;
//         padding: 0;
//         margin: 0;
//         li {
//           font-size: 16px;
//           width: 100%;
//           margin-top: 7px;
//
//           input {
//             width: 100%;
//             appearance: none;
//             line-height: 34px;
//           }
//           select {
//             width: 100%;
//           }
//         }
//       }
//     }
//
//     &.open {
//       .menu-annexe-content {
//         display: inline-block;
//       }
//     }
//
//     ${props => {
//       if (!props.theme.mobile) {
//         return {
//           [`.menu-annexe-content`]: {
//             left: 'auto',
//             width: '300px',
//           },
//         };
//       }
//
//       return null;
//     }};
//
//     .open-menu {
//       border: 1px solid #b7c6fe !important;
//     }
//   }
//
//   .navbar-left {
//     display: ${props => (props.theme.mobile ? 'inline-block' : 'inline-block')};
//   }
//
//   &.is-clearfix {
//     &:after {
//       clear: both;
//       content: ' ';
//       display: table;
//     }
//   }
//
//   &.isOverTop:not(.secondary) {
//     .content {
//       position: fixed;
//       top: 30px;
//       z-index: 10000;
//     }
//   }
//
//   .search-results {
//     position: absolute;
//     z-index: 100000;
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     left: -1px;
//     box-sizing: content-box;
//     top: 60px;
//     background: #fff;
//     box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
//
//     .search-results-item {
//       height: 32px;
//       display: flex;
//       padding: 0.75rem;
//       border-bottom: 1px solid #e4e7ed;
//
//       .search-results-item-image {
//         width: 32px;
//         height: 32px;
//       }
//
//       h3 {
//         margin: 0 0 0 1.5rem;
//         font-weight: normal;
//         line-height: 32px;
//       }
//     }
//   }
//
//   ul {
//     float: left;
//     margin: -7px 0 -7px 0;
//     padding: 7px 0;
//     list-style: none;
//     li {
//       display: inline-block;
//       margin: 0 7px 0 0;
//       line-height: 34px;
//     }
//
//     &.navbar-right {
//       float: right;
//       text-align: right;
//       li {
//         margin: 0 0 0 7px;
//       }
//     }
//
//     &.border-left {
//       border-left: 1px solid #e4e7ed;
//       padding-left: 7px;
//     }
//
//     &.navbar-right.border-left {
//       padding-left: 0;
//     }
//   }
//
//   .filter {
//     button {
//       margin-left: 7px;
//     }
//   }
//
//   .navbar-item {
//     input {
//       position: relative;
//       height: 34px;
//       box-sizing: border-box;
//       display: inline-block;
//       vertical-align: top;
//       padding: 0 0.75rem;
//       background: #fff;
//       box-shadow: none !important;
//       font-weight: 300;
//       line-height: 1.3334;
//       border-radius: 2px;
//       border: 1px solid #e4e7ed;
//       outline: none;
//     }
//
//     input[type='date'] {
//       width: 145px;
//     }
//
//     &.search {
//       position: relative;
//       input {
//         padding-right: 34px;
//       }
//
//       .empty {
//         .icon {
//           background-color: #e4e7ed;
//           mask-image: url(${close});
//           mask-repeat: no-repeat;
//           mask-position: center;
//           display: block;
//           width: 16px;
//           height: 16px;
//           position: absolute;
//           right: 9px;
//           top: 9px;
//         }
//         .text {
//           display: none;
//         }
//       }
//     }
//   }
//
//   .feature {
//     select {
//       position: relative;
//       height: 34px;
//       box-sizing: border-box;
//       display: inline-block;
//       vertical-align: top;
//       padding: 0 36px 0 0.75rem;
//       background: #fff;
//       box-shadow: none !important;
//       font-weight: 300;
//       line-height: 1.3334;
//       border-radius: 2px;
//       border: 1px solid #e4e7ed;
//       outline: none;
//       -moz-appearance: none;
//       -webkit-appearance: none;
//     }
//
//     &.select {
//       position: relative;
//
//       &:after {
//         border: 1px solid #e4e7ed;
//         border-right: 0;
//         border-top: 0;
//         content: ' ';
//         display: block;
//         height: 0.5em;
//         pointer-events: none;
//         position: absolute;
//         -webkit-transform: rotate(-45deg);
//         -ms-transform: rotate(-45deg);
//         transform: rotate(-45deg);
//         width: 0.5em;
//         margin-top: -0.375em;
//         right: 1.125em;
//         top: 50%;
//         z-index: 4;
//       }
//     }
//   }
//
//   .nbByRow {
//     text-align: right;
//     input {
//       width: 50px;
//     }
//   }
//
//   .navbar-right + .navbar-right,
//   .navbar-right + .menu-annexe,
//   .menu-annexe + .navbar-right {
//     margin-right: 0.375rem;
//   }
// `;

const ActionBar: FC<{ className?: string }> = ({ children, className }) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isOverTop = () => {
      if (
        !nodeRef ||
        !nodeRef.current ||
        !nodeRef.current.getBoundingClientRect
      ) {
        return;
      }

      const boundingClientRect = nodeRef.current.getBoundingClientRect();
      // const html = document.documentElement;
      // const windowWidth = window.innerWidth || html.clientWidth;
      // const windowHeight = window.innerHeight || html.clientHeight;

      if (window.scrollY - boundingClientRect.top > 30) {
        nodeRef.current.classList.add('isOverTop');
        // nodeRef.current.getElementsByClassName('content')[0].style.width = `${
        //   nodeRef.current.offsetWidth - 16
        // }px`;
      } else {
        nodeRef.current.classList.remove('isOverTop');
      }
    };

    const attachListener = () => {
      window.addEventListener('scroll', isOverTop);
      window.addEventListener('resize', isOverTop);
    };

    const removeListener = () => {
      window.removeEventListener('scroll', isOverTop);
      window.removeEventListener('resize', isOverTop);
    };

    attachListener();
    isOverTop();

    return () => {
      removeListener();
    };
  }, []);

  return (
    <div ref={nodeRef} className={className || 'relative h-12 mb-12'}>
      <div className="border border-mystic-500 bg-light-500 p-1.5 flex justify-between items-center">
        {children}
      </div>
    </div>
  );
};

export default ActionBar;
