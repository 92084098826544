import { Field } from 'dataformjs';
import {
  convertFromRaw,
  convertToRaw,
  DraftHandleValue,
  Editor,
  EditorState,
  RichUtils,
} from 'draft-js';
import React, { FC, useEffect, useRef, useState } from 'react';

import FieldSC from '../../sysext/CMS/styles/Field';
import { Draft as DraftSC } from './styles';
import {
  BlockStyleControls,
  decorator,
  InlineStyleControls,
  LinkControl,
} from './utils';

export const DraftEl: FC<{
  label?: string;
  input: {
    value: any;
    onChange: (e: any) => void;
  };
}> = ({ label, input }) => {
  const editorRef = useRef(null);
  const [hasValue, setHasValue] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(decorator),
  );

  useEffect(() => {
    if (!hasValue && input.value) {
      setHasValue(true);
      setEditorState(
        EditorState.createWithContent(convertFromRaw(input.value), decorator),
      );
    }
  }, [hasValue, input.value]);

  const onChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const value = convertToRaw(contentState);
    input.onChange(value);
  };

  const onBlur = () => {
    const contentState = editorState.getCurrentContent();
    const value = convertToRaw(contentState);
    input.onChange(value);
    setFocus(false);
  };

  const focusEditor = () => {
    setFocus(true);
    if (editorRef && editorRef.current) {
      // editorRef.current.focus();
    }
  };

  const handleKeyCommand = (
    command: string,
    newEditorState: EditorState,
  ): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(newEditorState, command);
    if (newState) {
      onChange(newState);

      return 'handled';
    }

    return 'not-handled';
  };

  const toggleBlockType = (blockType: string) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle: string) => {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  return (
    <FieldSC
      style={{
        height: 'auto',
        minHeight: '35px',
      }}
    >
      <DraftSC>
        <div className="controls">
          <BlockStyleControls
            editorState={editorState}
            onToggle={toggleBlockType}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={toggleInlineStyle}
          />
          <LinkControl
            editor={editorRef}
            editorState={editorState}
            onChange={onChange}
          />
        </div>
        <div className="editor" onClick={focusEditor} role="presentation">
          <Editor
            ref={editorRef}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            onBlur={onBlur}
            onChange={onChange}
          />
        </div>
      </DraftSC>
      <div className={`label ${focus ? 'is-focused' : ''}`}>
        <span>{label}</span>
      </div>
    </FieldSC>
  );
};

const DataDraft: FC<{
  prefix?: string;
  disabled?: boolean;
  onBlur?: () => void;
  className?: string;
  description?: string;
  label?: string;
  placeholder?: string;
  icon?: string;
  rows?: number;
  horizontal?: string;
  name: string;
}> = ({
  prefix,
  disabled,
  onBlur,
  className,
  description,
  label,
  placeholder,
  icon,
  rows,
  horizontal,
  name,
}) => {
  const newName = `${prefix ? `${prefix}.` : ''}${name}`;

  return (
    <Field
      className={className}
      component={DraftEl}
      description={description}
      disabled={disabled}
      horizontal={horizontal}
      icon={icon}
      label={label}
      name={newName}
      onBlur={onBlur}
      placeholder={placeholder}
      rows={rows}
    />
  );
};

export default DataDraft;
