import { getFirestore, UrlData } from '@innedit/innedit';
import classnames from 'classnames';
import { useSelector } from 'dataformjs';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from '../../../../../components/Button';
import IconDelete from '../../../../../icons/Delete';

const Div = styled.div`
  h3 {
    font-size: 11px;
    font-weight: 100;
    margin-top: 0;
    margin-bottom: 0.375rem;
  }
`;

const Pathnames: FC<{
  boutique: firebase.default.firestore.DocumentSnapshot;
  className?: string;
  docId: string;
  formName: string;
}> = ({ boutique, className, docId, formName }) => {
  const [urls, setUrls] =
    useState<firebase.default.firestore.QueryDocumentSnapshot[]>();
  const { pathname } = useSelector(
    (globalState: any) => globalState.form[formName].values,
  );

  useEffect(() => {
    UrlData.watch(
      querySnapshot => {
        setUrls(querySnapshot.docs);

        console.info('url watch urls', querySnapshot.size);

        // if (
        //   1 === querySnapshot.docs.length &&
        //   pathname !== querySnapshot.docs[0].get('pathname')
        // ) {
        //   changePathname(querySnapshot.docs[0].get('pathname'));
        // }
      },
      {
        boutique,
        wheres: {
          docId,
        },
      },
    );
  }, [boutique, docId, pathname]);

  const handleDeleteUrl = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );

    if (
      urls &&
      Number.isInteger(i) &&
      window.confirm('Voulez-vous vraiment supprimer cette adresse ?')
    ) {
      UrlData.delete(urls[i].ref).catch(error => {
        throw new Error(`Url.delete : ${error.message}`);
      });
    }
  };

  const handleSetDefaultUrl = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );
    if (urls && Number.isInteger(i) && urls[i]) {
      const docRef = urls[i].get('docRef')
        ? urls[i].get('docRef')
        : getFirestore(boutique)
            .collection(`${urls[i].get('type')}s`)
            .doc(urls[i].get('docId'));

      (docRef as firebase.default.firestore.DocumentReference)
        .update({
          pathname: urls[i].get('pathname'),
        })
        .catch(error => {
          throw new Error(
            `handleSetDefaultUrl : docRef.update : ${error.message}`,
          );
        });
    } else {
      console.error("L'url n'existe pas pour l'index", i);
    }
  };

  if (!urls) {
    return null;
  }

  return (
    <Div className={classnames(className, 'px-6')}>
      <h3>Liste des adresses associées :</h3>
      <ul>
        {urls.map((url, idx) => (
          <li key={url.id} className="flex justify-between mb-2">
            <span className="libelle">{url.get('pathname')}</span>
            <span className="flex space-x-1 items-center">
              {pathname !== url.get('pathname') && (
                <Button
                  data-index={idx}
                  onClick={handleSetDefaultUrl}
                  status="secondary"
                  text="url par défaut"
                />
              )}

              {urls.length > 1 && (
                <Button
                  data-index={idx}
                  iconLeft={IconDelete}
                  onClick={handleDeleteUrl}
                  status="delete-action"
                />
              )}
            </span>
          </li>
        ))}
      </ul>
    </Div>
  );
};

export default Pathnames;
