import { rem } from 'polished';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import borderRadius from '../../styles/borderRadius';
import font from '../../styles/font';
import { colors } from '../../styles/theme';
import transition from '../../styles/transition';
import { ButtonProps } from './index';

const ButtonText = styled.span``;

export const getButtonStatusProps = (
  status?: string,
): FlattenSimpleInterpolation => {
  switch (status) {
    case 'card-action': {
      return css`
        background: ${colors.white['50']};
        border: none;
        outline: 0;
        color: ${colors.neutral['300']} !important;
        text-align: center;
        text-decoration: none;
        padding: 0 !important;
        &:hover {
          color: ${colors.neutral['500']} !important;
        }
      `;
    }

    case 'field-action': {
      return css`
        background: ${colors.light['400']};
        border-color: ${colors.primary['400']};
        border-style: solid;
        outline: 0;
        color: ${colors.primary['400']} !important;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        height: ${rem(42)};
      `;
    }

    case 'delete-action': {
      return css`
        background: ${colors.danger['200']};
        border-style: none;
        outline: 0;
        color: ${colors.danger['500']} !important;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        height: ${rem(42)};
        border-radius: ${borderRadius.xs};
      `;
    }

    case 'menu-action': {
      return css`
        background: transparent;
        border: none;
        outline: 0;
        color: ${colors.neutral['500']} !important;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        height: ${rem(42)};
        padding: 0 !important;
        width: ${rem(42)};
      `;
    }

    case 'tab-action': {
      return css`
        background: none;
        border-top: 0;
        border-right: 0;
        border-bottom: 4px solid transparent;
        border-left: 0;
        color: #86848c;
        display: block;
        float: none;
        font-size: 14px;
        padding: 8px 10px;
        white-space: nowrap;

        &.is-active {
          background: 0 0;
          border-bottom-color: #4b71fc;
          border-radius: 0;
          color: #34313f;
          font-weight: 400;
        }
      `;
    }

    case 'link': {
      return css`
        background: none;
        border: none;
        color: #757575;
        text-decoration: underline;
        padding: 0 !important;
      `;
    }

    case 'secondary': {
      return css`
        background: ${colors.primary['200']};
        border-color: ${colors.primary['200']};
        border-style: solid;
        color: ${colors.primary['500']} !important;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        border-radius: ${borderRadius.xs};
      `;
    }

    // background: 'rgb(111, 141, 253)',

    // background: '#4b71fc',
    // borderColor: '#4b71fc',

    default: {
      return css`
        background: ${colors.primary['500']};
        border-color: ${colors.primary['500']};
        color: ${colors.white['500']} !important;
        border-radius: ${borderRadius.xs};
        line-height: 1rem;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
        }
      `;
    }
  }
};

const getButtonSizeProps = ({
  size,
  square,
}: {
  size?: string;
  square?: boolean;
}) => {
  switch (size) {
    case 'sm':
      return css`
        font-size: ${font.size.xs[0]};
        min-height: ${rem(32)};
        padding: ${rem(4)} ${square ? 0 : rem(12)};
        min-width: ${square ? rem(32) : 'initial'};
        > * + * {
          margin-left: ${rem(6)};
        }
        svg {
          height: ${rem(14)};
          width: ${rem(14)};
        }
      `;

    case 'lg':
      return css`
        min-height: ${rem(48)};
        padding: ${rem(8)} ${square ? 0 : rem(24)};
        min-width: ${square ? rem(48) : 'initial'};
        ${ButtonText} {
          min-width: ${'initial'};
        }
        > * + * {
          margin-left: ${rem(12)};
        }
        svg {
          height: ${rem(16)};
          width: ${rem(16)};
        }
      `;

    default:
      return css`
        min-height: ${rem(40)};
        padding: ${rem(11)} ${square ? 0 : rem(16)};
        min-width: ${square ? rem(40) : 'initial'};
        ${ButtonText} {
          min-width: ${'initial'};
        }
        > * + * {
          margin-left: ${rem(12)};
        }
      `;
  }
};

export default styled.button<ButtonProps>`
  appearance: none !important;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: ${font.weight.normal};
  text-align: center;
  transition: background ${transition.timing.base} ${transition.easing.base},
    color ${transition.timing.base} ${transition.easing.base},
    box-shadow ${transition.timing.base} ${transition.easing.base};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none !important' : 'auto')};

  ${({ status }) => getButtonStatusProps(status)};
  ${({ size, square }) => getButtonSizeProps({ size, square })};

  // border: 1px solid #b7c6fe;
  // box-shadow: none !important;
  // color: #4b71fc;
  // line-height: 1.3334;
  // border-right-width: 0;
  // border-radius: 0;
`;
