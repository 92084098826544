/* eslint-disable max-len */
import React, { FC } from 'react';

import IconProps from '../../props/Icon';

const IconExchangeAlt: FC<IconProps> = ({ color, size = 16 }) => (
  <svg height={size} viewBox="0 0 512 512" width={size}>
    <path
      d="M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default IconExchangeAlt;
