import { FirebaseFirestore } from '@innedit/innedit';
import moment from 'moment';

import {
  BOUTIQUE_CHANGE,
  BOUTIQUES_LOAD_REQUEST,
  BOUTIQUES_LOAD_SUCCESS,
  BOUTIQUES_RESET,
  BOUTIQUES_WATCH,
  DOCS_RESET,
  IS_MOBILE,
  IS_WEBAPP,
  LIST_COLLECTION_SET,
  LIST_DOCS_SET,
  LIST_SEARCH_SET,
  LIST_TAB_SET,
  NOTIFICATION_CREATE,
  NOTIFICATION_REMOVE,
  RESET,
  USER_IS_LOGOUT,
  USER_LOAD_ERROR,
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
} from './actionTypes';
import { ListTabProps } from './containers/Boutique/components/new/props';

interface reducerCMSProps {
  boutiques?: any[];
  docs?: any[];
  error?: string;
  indexBoutique: number;
  info?: string;
  isLogged: boolean;
  list: {
    collection?: string;
    docs?: FirebaseFirestore.QueryDocumentSnapshot[];
    q?: string;
    tab?: ListTabProps;
  };
  loadingBoutiques: boolean;
  loadingUser: boolean;
  mobile: boolean;
  notifications: {
    [key: string]: { key: string; message: string; type: string };
  };
  user?: any;
  webapp: boolean;
}
const initialState: reducerCMSProps = {
  boutiques: undefined,
  docs: undefined,
  error: undefined,
  indexBoutique: 0,
  info: undefined,
  isLogged: false,
  list: {
    collection: undefined,
    docs: undefined,
    q: undefined,
    tab: undefined,
  },
  loadingBoutiques: true,
  loadingUser: true,
  mobile: false,
  notifications: {},
  user: undefined,
  webapp: false,
};

const reducerCMS = (
  state = initialState,
  action: { [key: string]: any },
): reducerCMSProps => {
  let notifications;

  switch (action.type) {
    case IS_MOBILE:
      return { ...state, mobile: action.test };

    case IS_WEBAPP:
      return { ...state, webapp: action.test };

    case USER_LOAD_REQUEST:
      return { ...state, isLogged: false, loadingUser: true, user: null };

    case USER_LOAD_SUCCESS:
      if (action.user.exists) {
        return {
          ...state,
          error: undefined,
          info: undefined,
          isLogged: true,
          loadingUser: false,
          user: action.user,
        };
      }

      return {
        ...state,
        error: "L'utilisateur n'existe pas",
        info: undefined,
        isLogged: false,
        loadingUser: false,
        user: null,
      };

    case USER_LOAD_ERROR:
      return {
        ...state,
        error: action.message,
        isLogged: false,
        loadingUser: false,
        user: null,
      };

    case USER_IS_LOGOUT:
      return {
        ...state,
        error: undefined,
        info: "L'utilisateur est déconnecté",
        isLogged: false,
        loadingUser: false,
        user: null,
      };

    case BOUTIQUES_LOAD_REQUEST:
      return { ...state, boutiques: undefined, loadingBoutiques: true };

    case BOUTIQUES_LOAD_SUCCESS:
    case BOUTIQUES_WATCH: {
      const indexBoutique = state.indexBoutique
        ? state.indexBoutique
        : action.indexBoutique;

      return {
        ...state,
        indexBoutique,
        boutiques: action.boutiques,
        loadingBoutiques: false,
      };
    }

    case BOUTIQUES_RESET:
      return { ...state, boutiques: undefined, loadingBoutiques: false };

    case BOUTIQUE_CHANGE:
      return { ...state, indexBoutique: action.index };

    case DOCS_RESET:
      return { ...state, docs: undefined };

    case NOTIFICATION_CREATE: {
      notifications = { ...state.notifications };
      const date = moment().format('x');
      notifications[date] = { ...action.notification, key: date };

      return { ...state, notifications };
    }

    case NOTIFICATION_REMOVE:
      notifications = { ...state.notifications };
      delete notifications[action.key];

      return { ...state, notifications };

    case LIST_COLLECTION_SET:
      return {
        ...state,
        list: { ...state.list, collection: action.collection },
      };

    case LIST_DOCS_SET:
      return {
        ...state,
        list: { ...state.list, docs: action.docs },
      };

    case LIST_SEARCH_SET:
      return {
        ...state,
        list: { ...state.list, q: action.q },
      };

    case LIST_TAB_SET:
      return { ...state, list: { ...state.list, tab: action.tab } };

    case RESET:
      return initialState;

    default:
  }

  return state;
};

export default reducerCMS;
