import { FirebaseFirestore } from '@innedit/innedit';
import {
  change,
  DataArrayProps,
  FieldArray,
  useDispatch,
  useSelector,
} from 'dataformjs';
import React, { FC, useEffect } from 'react';

import DimensionsRender from './Render';

export interface DimensionsProps extends DataArrayProps {
  boutique: FirebaseFirestore.DocumentSnapshot;
  name: string;
}

const Dimensions: FC<DimensionsProps> = props => {
  const { boutique, formName, name } = props;
  const { category, dimensions } = useSelector(
    (globalState: any) => globalState.form[formName].values,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (category) {
      boutique.ref
        .collection('productCategories')
        .doc(category)
        .get()
        .then(snapshot => {
          if (snapshot.exists) {
            // On récupére toutes les dimensions
            return snapshot.ref
              .collection('dimensions')
              .where('deleted', '==', false)
              .get()
              .then(querySnapshot => {
                if (!querySnapshot.empty) {
                  const tmpDimensions = dimensions ? [...dimensions] : [];
                  let addOne = false;
                  querySnapshot.docs.forEach(doc => {
                    if (
                      !dimensions ||
                      !dimensions.some(
                        (d: { id?: string }) => d.id && d.id === doc.id,
                      )
                    ) {
                      addOne = true;
                      tmpDimensions.push({
                        id: doc.id,
                        libelle: doc.get('libelle'),
                        valeur: '',
                      });
                    }
                  });

                  if (addOne) {
                    dispatch(change(formName, 'dimensions', tmpDimensions));
                  }
                }

                return true;
              })
              .catch((error: Error) => {
                console.error(error);
              });
          }

          return true;
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  }, [boutique, category, dimensions, dispatch, formName]);

  return <FieldArray component={DimensionsRender} name={name} props={props} />;
};

export default Dimensions;
