import classnames from 'classnames';
import styled, { ThemeProps } from 'styled-components';

import colors from '../../styles/colors';
import spacing from '../../styles/spacing';
import { DefaultThemeProps, font } from '../../styles/theme';

const Group = styled.div<ThemeProps<DefaultThemeProps>>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1.5rem;

  display: flex;
  flex-direction: column;

  &.alt {
    background-color: #fbfbfc;
    border: 1px solid rgba(228, 231, 237, 0.4);
  }

  > p {
    margin: 1.5rem;
  }
`;

export const GroupBody = styled.div`
  background: ${colors.white[50]};
  border: 1px solid ${colors.neutral[200]};
  padding: ${spacing[6]};
`;

export const GroupDescription = styled.p.attrs(props => ({
  className: classnames(props.className, 'mb-5'),
}))``;

export const GroupTitle = styled.h2`
  text-align: left;
  margin-bottom: ${spacing[2]};
  font-size: ${font.size.lg[0]};
`;

export default Group;
