import { WrappedFieldArrayProps } from 'dataformjs';
import objectHash from 'object-hash';
import React, { FC } from 'react';

import Button from '../../../../../../../components/Button';
import IconClose from '../../../../../../../icons/Close';
import HOCGroup from '../../../../../components/HOC/Group';
import Field from '../../../../../styles/Field';
import Select from '../Select';
import { ListProps } from './index';

const ListRender: FC<WrappedFieldArrayProps & ListProps> = ({
  fields,
  formName,
  title,
}) => {
  const handleAdd = () => {
    fields.push('');
  };

  const handleRemove = (index: number) => {
    fields.remove(index);
  };

  return (
    <HOCGroup
      addOnClick={handleAdd}
      addText="Ajouter une boutique"
      title={title}
    >
      {fields.length > 0 ? (
        fields.map((item, index) => (
          <li key={objectHash({ item })} className="flex">
            <Select
              formName={formName}
              label="boutique"
              name={item}
              wrapperProps={{ className: 'flex-1' }}
            />
            <Field noMargin>
              <div className="has-addons">
                <Button
                  className="no-left-radius"
                  iconLeft={IconClose}
                  onClick={() => handleRemove(index)}
                />
              </div>
            </Field>
          </li>
        ))
      ) : (
        <div style={{ margin: '1.5rem' }}>Aucune boutique</div>
      )}
    </HOCGroup>
  );
};

export default ListRender;
