import React, { FC, SyntheticEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Button, { ButtonProps } from '../../../../components/Button';
import Portal from '../../../../utils/Portal';
import StyledModal from '../../styles/Modal';

interface ModalProps {
  buttons?: ButtonProps[];
  closeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  title: string;
}

const Modal: FC<ModalProps> = ({ buttons, children, closeOnClick, title }) => (
  <Portal>
    <StyledModal>
      <div className="dialog">
        <div className="dialog__header">
          <h3>{title}</h3>
          <button
            className="dialog--header--close"
            onClick={closeOnClick}
            type="button"
          >
            <span className="text">Fermer</span>
          </button>
        </div>
        <div className="dialog__content">{children}</div>
        {buttons && buttons.length > 0 && (
          <div className="dialog__footer">
            <ul>
              {buttons.map(button => (
                <li key={uuidv4()}>
                  <Button {...button} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </StyledModal>
  </Portal>
);

export default Modal;
