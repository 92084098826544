import classnames from 'classnames';
import objectHash from 'object-hash';
import React, { FC } from 'react';
import slug from 'slug';

import Button from '../../../../components/Button';
import {
  ListTabProps,
  SearchProps,
} from '../../containers/Boutique/components/new/props';
import Tabs from '../../styles/Tabs';
import ViewList from '../../styles/View/List';
import ViewTitle from '../../styles/View/Title';
import Header from '../Header';
import { MenuProp } from '../Menu';

interface ListProps {
  className?: string;
  description?: string | string[];
  menu?: MenuProp;
  search?: SearchProps;
  tabIndex?: number;
  tabs?: ListTabProps | ListTabProps[];
  title: string | any | any[];
}
const List: FC<ListProps> = ({
  children,
  className,
  description,
  menu,
  search,
  tabIndex = 0,
  tabs,
  title,
}) => (
  <ViewList className={className}>
    <ViewTitle>{title}</ViewTitle>
    {tabs && (
      <Tabs>
        <ul>
          {(Array.isArray(tabs) ? tabs : [tabs]).map((tab, index) => (
            <li key={objectHash(tab)}>
              <Button
                className={classnames('focus:outline-none', {
                  'is-active': tabIndex === index,
                })}
                data-index={index}
                status="tab-action"
                to={tab.pathname}
                type="button"
              >
                {tab.label}
              </Button>
            </li>
          ))}
        </ul>
      </Tabs>
    )}

    <Header menu={menu} search={search} />

    {description &&
      (!Array.isArray(description) ? [description] : description).map(d => (
        <p key={slug(d.slice(0, 10))} className="description">
          {d}
        </p>
      ))}
    {children}
  </ViewList>
);

export default List;
