import { DataProps } from 'dataformjs';
import React, { FC } from 'react';

import List from './Data/List';
import MultiSelect from './Data/MultiSelect';
import Select from './Data/Select';

const Data: FC<
  DataProps & {
    boutique?: firebase.default.firestore.DocumentSnapshot;
    collectionName?: string;
    formName: string;
    libelleField?: any;
    modalFormName?: string;
    modalTitle?: string;
    type: string;
  }
> = props => {
  const {
    boutique,
    collectionName,
    formName,
    modalFormName,
    modalTitle,
    name,
    params,
    title,
    type,
  } = props;

  switch (type) {
    case 'list':
      if (!name) {
        return <div>list : erreur de paramètre : name est obligatoire</div>;
      }
      if (!title) {
        return <div>list : erreur de paramètre : title est obligatoire</div>;
      }

      if (!params || !params.docRef) {
        return (
          <div>list : erreur de paramètre : params.docRef est obligatoire</div>
        );
      }

      return (
        <List
          {...props}
          docRef={params.docRef}
          modalFormName={modalFormName}
          modalTitle={modalTitle}
          name={name}
          title={title}
        />
      );

    case 'multiselect':
      if (!boutique) {
        return (
          <div>
            multiselect : erreur de paramètre : boutique est obligatoire
          </div>
        );
      }
      if (!collectionName) {
        return (
          <div>
            multiselect : erreur de paramètre : collectionName est obligatoire
          </div>
        );
      }
      if (!name) {
        return (
          <div>
            feature/multiselect : erreur de paramètre : name est obligatoire
          </div>
        );
      }

      return (
        <MultiSelect
          {...props}
          boutique={boutique}
          collectionName={collectionName}
          name={name}
        />
      );

    case 'select':
      if (!boutique) {
        return (
          <div>select: erreur de paramètre : boutique est obligatoire</div>
        );
      }
      if (!collectionName) {
        return (
          <div>
            select: erreur de paramètre : collectionName est obligatoire
          </div>
        );
      }
      if (!name) {
        return <div>select: erreur de paramètre : name est obligatoire</div>;
      }

      return (
        <Select
          {...props}
          boutique={boutique}
          collectionName={collectionName}
          componentType="feature"
          formName={formName}
          name={name}
        />
      );

    default:
  }

  return <div>Ce type de composant n&apos;existe pas : {type}</div>;
};

export default Data;
