import { rem } from 'polished';
import styled, {
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';

import { DefaultThemeProps, spacing } from '../../styles/theme';
import { getStatusStyle, StatusProp } from '../../utils/getControlStyle';

export const FieldMessage = styled.div<
  ThemeProps<DefaultThemeProps> & StatusProp
>`
  transform: translateY(${rem(4)});
  font-size: ${(props: ThemeProps<DefaultThemeProps>): string =>
    props.theme.font.size.xs[0]};
  ${(props: StatusProp): FlattenSimpleInterpolation => getStatusStyle(props)};
`;

export const FieldTemplate = styled.div`
  display: flex;
  align-items: center;
`;

export const FieldWrapper = styled.div`
  margin-bottom: ${spacing[4]};

  &:last-child {
    margin-bottom: 0;
  }
`;
