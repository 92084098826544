import { UrlData } from '@innedit/innedit';
import { change, connect, DataField, Dispatch } from 'dataformjs';
import React, { FC, SyntheticEvent } from 'react';

import Button from '../../../../../components/Button';
import IconEraser from '../../../../../icons/Eraser';

interface InputUrlProps {
  boutique?: firebase.default.firestore.DocumentSnapshot;
  field: string;
  formName: string;
  docId: string;
  label?: string;
  name: string;
  parentId?: string;
  placeholder?: string;
  urlType: 'page' | 'post' | 'produit';
}
const InputUrl: FC<
  InputUrlProps & {
    libelle: string;
    dispatch: Dispatch;
  }
> = ({
  boutique,
  dispatch,
  docId,
  formName,
  label,
  libelle,
  name,
  parentId,
  placeholder,
  urlType,
}) => {
  const handleBlur = (props: {
    event?: SyntheticEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >;
    name?: string;
    value?: any;
  }) => {
    const { value } = props;
    console.info('value', value);

    if (docId && value) {
      UrlData.clear(value, urlType, docId, {
        boutique,
        parentId,
        removeType: true,
      })
        .then(url =>
          dispatch(change(formName, 'pathname', url.get('pathname'))),
        )
        .catch(error => {
          throw new Error(`Url.clear : ${error.message}`);
        });
    }
  };

  const handleClear = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    UrlData.clear(libelle, urlType, docId, { boutique, parentId })
      .then(url => dispatch(change(formName, 'pathname', url.get('pathname'))))
      .catch(error => {
        throw new Error(`Url.clear : ${error.message}`);
      });
  };

  return (
    <div className="flex items-end">
      <DataField
        componentType="input"
        customAction={
          <Button
            iconLeft={IconEraser}
            onClick={handleClear}
            status="field-action"
          />
        }
        fieldProps={{
          className: 'w-full flex-1 border-r-0 rounded-r-none',
        }}
        handleOnBlur={handleBlur}
        label={label}
        name={name}
        placeholder={placeholder}
        type="text"
        wrapperProps={{ className: 'flex-1' }}
      />
    </div>
  );
};

const mapStateToProps = (globalState: any, ownProps: InputUrlProps) => {
  const { values } = globalState.form[ownProps.formName];

  return {
    libelle: values[ownProps.field],
    parentId: values.parent,
  };
};

export default connect(mapStateToProps)(InputUrl);
