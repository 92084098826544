import { DataProps } from 'dataformjs';
import moment from 'moment';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import HOCGroup from '../../../../../components/HOC/Group';
import Item from './Item';

// const sortFunc = (a, b) => b.datetime - a.datetime;

export interface FieldProps {
  label: string;
  name: string;
  type: string;
}

export interface LibelleProps {
  component?: string;
  name: string;
  options?: {
    value: string;
    label: string;
  }[];
}

interface ListProps extends DataProps {
  addLabel?: string;
  customFields?: FieldProps | FieldProps[];
  docRef: firebase.default.firestore.DocumentReference;
  libelle?: LibelleProps;
  modalDatas?: DataProps | DataProps[];
  modalFormName?: string;
  modalTitle?: string;
  name: string;
  nothingLabel?: string;
  orderDirection?: firebase.default.firestore.OrderByDirection;
  orderField?: string;
  showLibelle?: boolean;
}

const List: FC<ListProps> = ({
  addLabel,
  customFields,
  docRef,
  libelle,
  modalDatas,
  modalFormName,
  modalTitle,
  name,
  nothingLabel,
  title,
  showLibelle = true,
}) => {
  const [docs, setDocs] =
    useState<firebase.default.firestore.QueryDocumentSnapshot[]>();
  const [timeOuts, setTimeOuts] = useState<{ [id: string]: NodeJS.Timeout }>(
    {},
  );

  useEffect(() => {
    docRef
      .collection(name)
      .where('deleted', '==', false)
      .orderBy('datetime', 'desc')
      .onSnapshot(querySnapshot => {
        console.info('new documents');

        return setDocs(querySnapshot.docs);
      });
  }, [docRef, name]);

  const handleAdd = () => {
    // TODO améliorer la gestion avec un watch
    const data: { [key: string]: any } = {
      libelle: '',
    };

    if (customFields) {
      (Array.isArray(customFields) ? customFields : [customFields]).forEach(
        field => {
          data[field.name] = '';
        },
      );
    }

    docRef
      .collection(name)
      .add({
        createdAt: moment().toISOString(),
        datetime: parseInt(moment().format('x'), 10),
        deleted: false,
        ...data,
      })
      .catch(error => {
        throw new Error(`Features List : handleAdd() : ${error.message}`);
      });
  };

  const handleOnChange = (
    event: SyntheticEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const index = event.currentTarget.getAttribute('data-index');
    const attr = event.currentTarget.getAttribute('data-name');
    const { value } = event.currentTarget;

    if (docs && index && attr) {
      const item = docs[parseInt(index, 10)];

      if (timeOuts && timeOuts[item.id]) {
        clearTimeout(timeOuts[item.id]);
      }

      const timeoutId = setTimeout(() => {
        console.info('save', item.id);
        item.ref.update({
          [attr]: value,
          updatedAt: moment().toISOString(),
        });
      }, 1000);

      setTimeOuts(oldTimeOuts => ({ ...oldTimeOuts, [item.id]: timeoutId }));
    }
  };

  const handleRemoveOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    console.info('handleRemoveOnClick', event);
    const index = event.currentTarget.getAttribute('data-index');
    if (docs && index) {
      docs[parseInt(index, 10)].ref.update({
        deleted: true,
        updatedAt: moment().toISOString(),
      });
    }
  };

  const handleChangePosition = (oldIndex: number, newIndex: number) => {
    if (docs && newIndex - oldIndex !== 1) {
      let datetime = parseInt(moment().format('x'), 10);
      const newDatetime = docs[newIndex].get('datetime');

      if (0 < newIndex) {
        const beforeDatetime = docs[newIndex - 1].get('datetime');
        const diff = Math.floor((beforeDatetime - newDatetime) / 2);
        datetime = newDatetime + diff;
      }
      if (datetime === docs[newIndex].get('datetime')) {
        console.error(
          "le nouveau datetime est le même que celui de l'ancienne position",
        );
      }

      docs[oldIndex].ref
        .update({
          datetime,
          updatedAt: moment().toISOString(),
        })
        .catch(error => {
          console.error(error.message);
        });
    }
  };

  return (
    <HOCGroup
      addOnClick={handleAdd}
      addText={addLabel || 'Ajouter'}
      title={title}
    >
      {!docs && <p className="mb-0 p-6">Chargement en cours</p>}
      {docs && 0 === docs.length ? (
        <p className="mb-0 p-6">{nothingLabel || 'Aucun élément'}</p>
      ) : (
        <div className="mb-6">
          {docs?.map((doc, index) => (
            <Item
              key={doc.id || index}
              customFields={customFields}
              doc={doc}
              handleChangePosition={handleChangePosition}
              index={index}
              libelle={libelle}
              modalDatas={modalDatas}
              modalFormName={modalFormName}
              modalTitle={modalTitle}
              onChange={handleOnChange}
              removeOnClick={handleRemoveOnClick}
              showLibelle={showLibelle}
            />
          ))}
        </div>
      )}
    </HOCGroup>
  );
};

export default List;
