import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '../../sysext/CMS/components/Tooltip';
import { ButtonProps } from './index';
import ButtonSC from './style';

const ButtonDate: FC<ButtonProps> = ({
  children,
  className,
  text,
  type,
  value,
  ...others
}) => {
  const idRef = useRef<HTMLButtonElement>(null);
  const [showId, setShowId] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const show = () => {
      setShowId(true);
    };

    const hide = () => {
      setShowId(false);
    };

    const clean = () => {
      if (idRef && idRef.current) {
        idRef.current.removeEventListener('mouseover', show);
        idRef.current.removeEventListener('mouseout', hide);
      }
    };

    if (idRef && idRef.current) {
      idRef.current.addEventListener('mouseover', show);
      idRef.current.addEventListener('mouseout', hide);
    }

    return () => {
      clean();
    };
  }, []);

  return (
    <ButtonSC ref={idRef} className={className} {...others}>
      {children}
      <span className="icon">
        <i className="fa fa-calendar" />
      </span>
      {text && <span className="text">{t(text)}</span>}
      {showId && (
        <Tooltip
          direction="left"
          style={{ right: '50px', top: '3px' }}
          value={value}
        />
      )}
    </ButtonSC>
  );
};

export default ButtonDate;
