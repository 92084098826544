import { FeatureData, WhereProps } from '@innedit/innedit';
import { DataField, DataFieldProps } from 'dataformjs';
import React, { FC, useEffect, useState } from 'react';

interface SelectProps extends DataFieldProps {
  boutique: firebase.default.firestore.DocumentSnapshot;
  collectionName: string;
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
  wheres?: WhereProps;
}
const Select: FC<SelectProps> = ({
  boutique,
  className,
  collectionName,
  formName,
  label,
  name,
  orderDirection,
  orderField,
  wheres,
}) => {
  const [options, setOptions] =
    useState<firebase.default.firestore.QueryDocumentSnapshot[]>();

  useEffect(() => {
    const feature = new FeatureData(boutique, {
      collectionName,
      orderDirection,
      orderField,
    });
    const unsub: () => void = feature.watch(
      snapshot => {
        setOptions(snapshot.docs);
      },
      {
        wheres,
      },
    );

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique, collectionName, orderDirection, orderField]);

  if (!options || 0 === options.length) {
    return null;
  }

  return (
    <DataField
      className={className}
      componentType="select"
      formName={formName}
      label={label}
      name={name}
      options={options.map(item => ({
        label: item.get('libelle'),
        value: item.id,
      }))}
    />
  );
};

export default Select;
