import { connect } from 'dataformjs';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import { remove } from '../Notification/actions';

const Div = styled.div`
  line-height: 34px;
  float: right;
`;

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: undefined,
      mustInitRemove: false,
    };

    this.initRemove = this.initRemove.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const newState = state;
    if (props.notifications && Object.keys(props.notifications).length > 0) {
      if (
        !state.key ||
        Object.keys(props.notifications)[
          Object.keys(props.notifications).length - 1
        ] !== state.key
      ) {
        newState.key = Object.keys(props.notifications)[
          Object.keys(props.notifications).length - 1
        ];
        newState.mustInitRemove = true;
      }
    }

    return newState;
  }

  componentDidMount() {
    const { mustInitRemove } = this.state;

    if (mustInitRemove) {
      this.initRemove();
    }
  }

  componentDidUpdate() {
    const { mustInitRemove } = this.state;

    if (mustInitRemove) {
      this.initRemove();
    }
  }

  initRemove() {
    const { removeNotification } = this.props;
    const { key } = this.state;
    this.setState({
      mustInitRemove: false,
    });
    setTimeout(() => {
      removeNotification(key);
    }, 3000);
  }

  render() {
    const { notifications } = this.props;

    if (!notifications || 0 === Object.keys(notifications).length) {
      return null;
    }

    const key =
      Object.keys(notifications)[Object.keys(notifications).length - 1];

    return <Div>{notifications[key].message}</Div>;
  }
}

Notification.defaultProps = {
  notifications: null,
};

Notification.propTypes = {
  notifications: PropTypes.shape({}),
  removeNotification: PropTypes.func.isRequired,
};

const mapStateToProps = globalState => ({
  notifications: globalState.cms.notifications,
});

const mapDispatchToProps = dispatch => ({
  removeNotification: key => dispatch(remove(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
