import { rem, rgba } from 'polished';
import styled, {
  css,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';

import colors from '../styles/colors';
import control from '../styles/control';
import spacing from '../styles/spacing';
import { DefaultThemeProps } from '../styles/theme';
import transition from '../styles/transition';

export interface StatusProp {
  status?:
    | 'dark'
    | 'danger'
    | 'error'
    | 'info'
    | 'light'
    | 'link'
    | 'neutral'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'tertiary'
    | 'warning';
}

export const getStatusStyle = ({
  status,
}: StatusProp): FlattenSimpleInterpolation => {
  switch (status) {
    case 'error':
      return css`
        border-color: ${colors.danger[500]};
      `;
    case 'warning':
      return css`
        border-color: ${colors.warning[500]};
      `;
    default:
      return css`
        border-color: ${control.border.color.base};
      `;
  }
};

export const inputDisabledStyles = css`
  opacity: 0.5;
  pointer-events: none;
`;

const getControlStyle = (
  props: ThemeProps<DefaultThemeProps> & StatusProp,
): FlattenSimpleInterpolation => css`
  ${getStatusStyle(props)};
  display: flex;
  border-style: ${control.border.type};
  border-width: ${control.border.size.base}px;
  border-radius: ${control.radius.base};
  background-color: ${control.bg.color.base};
  border-radius: ${control.radius.base};
  line-height: 2.5rem;
  padding-left: ${spacing[4]};
  padding-right: ${spacing[4]};
  transition: border-color ${transition.timing.base} ${transition.easing.base},
    box-shadow ${transition.timing.base} ${transition.easing.base},
    background-color ${transition.timing.base} ${transition.easing.base};

  &:not([type='checkbox']):not([type='radio']):focus,
  &:not([type='checkbox']):not([type='radio']):focus-within {
    outline: none;
    box-shadow: 0 0 ${rem(1)} ${rem(3)}
      ${rgba(control.border.color.hover, 0.125)};
  }
  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${control.border.color.hover};
    background-color: ${control.bg.color.focus};
  }
  &:disabled {
    ${inputDisabledStyles};
    border-color: ${control.border.color.base};
  }

  ::placeholder {
    color: hsl(0, 0%, 50%);
  }
`;

export const ControlSC = styled.div`
  ${getControlStyle};
  display: block;
  width: 100%;
`;

export default getControlStyle;
