/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */

module.exports = {
  white: {
    50: '#ffffff',
    100: '#ffffff',
    200: '#feffff',
    300: '#fdfffe',
    400: '#fcfefe',
    500: '#FBFEFD',
    600: '#e2e5e4',
    700: '#bcbfbe',
    800: '#979898',
    900: '#7b7c7c',
  },
  black: {
    50: '#f2f2f2',
    100: '#e6e6e6',
    200: '#c0c0c0',
    300: '#9a9a9b',
    400: '#4e4f4f',
    500: '#020304',
    600: '#020304',
    700: '#020203',
    800: '#010202',
    900: '#010102',
  },
  light: {
    50: '#ffffff',
    100: '#fefefe',
    200: '#fefefe',
    300: '#fdfdfd',
    400: '#fbfbfb',
    500: '#f9f9f9',
    600: '#e0e0e0',
    700: '#bbbbbb',
    800: '#959595',
    900: '#7a7a7a',
  },
  dark: {
    50: '#f4f4f4',
    100: '#e8e8e9',
    200: '#c6c7c8',
    300: '#a4a5a6',
    400: '#5f6164',
    500: '#1b1d21',
    600: '#181a1e',
    700: '#141619',
    800: '#101114',
    900: '#0d0e10',
  },
  neutral: {
    50: '#f3f4f7',
    100: '#e7e9ee',
    200: '#c4c8d5',
    300: '#a0a7bb',
    400: '#586488',
    500: '#112255',
    600: '#0f1f4d',
    700: '#0d1a40',
    800: '#0a1433',
    900: '#08112a',
  },
  primary: {
    50: '#f4f5f9',
    100: '#e9ebf3',
    200: '#c9cee2',
    300: '#a9b0d0',
    400: '#6875ad',
    500: '#273a8a',
    600: '#23347c',
    700: '#1d2c68',
    800: '#172353',
    900: '#131c44',
  },
  secondary: {
    50: '#f6f8ff',
    100: '#edf1ff',
    200: '#d2dcfe',
    300: '#b7c6fe',
    400: '#819cfd',
    500: '#4b71fc',
    600: '#4466e3',
    700: '#3855bd',
    800: '#2d4497',
    900: '#25377b',
  },
  tertiary: {
    50: '#fdfdfd',
    100: '#fafafa',
    200: '#f3f3f3',
    300: '#ececec',
    400: '#dedede',
    500: '#d0d0d0',
    600: '#bbbbbb',
    700: '#9c9c9c',
    800: '#7d7d7d',
    900: '#666666',
  },
  quaternary: {
    50: '#fffafc',
    100: '#fff6f9',
    200: '#ffe8ef',
    300: '#ffdae5',
    400: '#ffbed2',
    500: '#ffa2bf',
    600: '#e692ac',
    700: '#bf7a8f',
    800: '#996173',
    900: '#7d4f5e',
  },
  success: {
    50: '#f4faf4',
    100: '#e8f4e8',
    200: '#c7e4c7',
    300: '#a5d4a5',
    400: '#61b361',
    500: '#1d931d',
    600: '#1a841a',
    700: '#166e16',
    800: '#115811',
    900: '#0e480e',
  },
  info: {
    50: '#f2f8fd',
    100: '#e6f2fb',
    200: '#c0def5',
    300: '#9bcaef',
    400: '#4fa3e2',
    500: '#047bd6',
    600: '#046fc1',
    700: '#035ca1',
    800: '#024a80',
    900: '#023c69',
  },
  warning: {
    50: '#fffcf2',
    100: '#fffae6',
    200: '#fff2bf',
    300: '#ffeb99',
    400: '#ffdb4d',
    500: '#ffcc00',
    600: '#e6b800',
    700: '#bf9900',
    800: '#997a00',
    900: '#7d6400',
  },
  danger: {
    50: '#fdf5f4',
    100: '#faece9',
    200: '#f3cfc8',
    300: '#ebb3a6',
    400: '#dd7964',
    500: '#ce4021',
    600: '#b93a1e',
    700: '#9b3019',
    800: '#7c2614',
    900: '#651f10',
  },
  transparent: 'transparent',
};
