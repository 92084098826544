import {
  change,
  DataArrayProps,
  FieldArray,
  Group,
  useDispatch,
  useSelector,
} from 'dataformjs';
import React, { FC, memo, useEffect, useState } from 'react';

import CaracteristiquesRender from './Render';

export interface CaracteristiquesProps extends DataArrayProps {
  boutique: firebase.default.firestore.DocumentSnapshot;
  formName: string;
  name: string;
}

const Caracteristiques: FC<CaracteristiquesProps> = props => {
  const [productFeatures, setProductFeatures] =
    useState<firebase.default.firestore.QueryDocumentSnapshot[]>();
  const { boutique, className, formName, name, label } = props;

  const dispatch = useDispatch();
  const {
    values: { features },
  } = useSelector((state: any) => state.form[formName]);

  console.info('values', features);
  useEffect(() => {
    console.info('features', features);
    boutique.ref
      .collection('productFeatures')
      .where('deleted', '==', false)
      .orderBy('libelle', 'asc')
      .get()
      .then(querySnapshot => {
        console.info('Caracteristiques', querySnapshot);
        if (!querySnapshot.empty) {
          const tmpFeatures = querySnapshot.docs.map(doc => ({
            id: doc.id,
            libelle: doc.get('libelle'),
            valeur: '',
          }));

          if (!features || !Array.isArray(features) || 0 === features.length) {
            dispatch(change(formName, 'features', tmpFeatures));
          } else {
            // On regarde si la feature est dans le tableau
            let hasAddFeature = false;
            tmpFeatures.forEach(doc => {
              if (!features.some(feature => feature.id === doc.id)) {
                features.push(doc);
                hasAddFeature = true;
              }
            });

            if (hasAddFeature) {
              dispatch(change(formName, 'features', features));
            }
          }
        }

        return setProductFeatures(querySnapshot.docs);
      })
      .catch((error: Error) => {
        throw new Error(`boutique.ref
      .collection('productFeatures') : ${error.message}`);
      });
  }, [boutique, formName]);

  return (
    <Group className={className} title={label}>
      <FieldArray
        component={CaracteristiquesRender}
        name={name}
        props={{ ...props, features: productFeatures }}
      />
    </Group>
  );
};

export default memo(Caracteristiques);
