import { FieldArray, WrappedFieldArrayProps } from 'dataformjs';
import React, { FC } from 'react';

import Medias, { MediaProps } from '../../../CMS/components/Medias';

const Photos: FC<
  WrappedFieldArrayProps & {
    boutique: firebase.default.firestore.DocumentSnapshot;
    docType: string;
    id: string;
  }
> = ({ boutique, fields, docType, id }) => {
  const handleAddItems = (
    medias: firebase.default.firestore.DocumentSnapshot[],
  ): void => {
    medias.forEach(media => {
      if (media.get('fullPath')) {
        fields.push(media.get('fullPath'));
      }
    });
  };

  const handleChangePosition = (oldIndex: number, newIndex: number): void => {
    const photo = fields.get(oldIndex);
    if (newIndex !== oldIndex) {
      if (newIndex < oldIndex) {
        fields.insert(newIndex, photo);
        fields.remove(oldIndex + 1);
      } else {
        // la nouvelle position est après
        fields.remove(oldIndex);
        fields.insert(newIndex, photo);
      }
    }
  };

  const handleRemoveItem = (index: number): void => {
    fields.remove(index);
  };

  const items: MediaProps[] =
    fields.length > 0
      ? fields.getAll().map(field => ({ fullPath: field }))
      : [];

  return (
    <Medias
      addText="Ajouter une photo"
      boutique={boutique}
      docId={id}
      docType={docType}
      handleAddItems={handleAddItems}
      handleChangePosition={handleChangePosition}
      handleRemoveItem={handleRemoveItem}
      items={items}
      title="Photos"
    />
  );
};

const DataPhotos: FC<any> = props => (
  <FieldArray {...props} component={Photos} />
);

export default DataPhotos;
