import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  EditorState,
  RichUtils,
} from 'draft-js';
import React, {
  FC,
  KeyboardEvent,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';

const StyleButton: FC<{
  label: string;
  onToggle: (style: string) => void;
  style: string;
  active?: boolean;
}> = ({ label, onToggle, style, active = false }) => {
  const handleOnToggle = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    onToggle(style);
  };
  let className = 'RichEditor-styleButton';
  if (active) {
    className += ' RichEditor-activeButton';
  }

  return (
    <span
      className={className}
      onMouseDown={handleOnToggle}
      role="presentation"
    >
      {label}
    </span>
  );
};

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  // { label: "Blockquote", style: "blockquote" },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  // { label: "Code Block", style: "code-block" }
];

export const BlockStyleControls: FC<{
  editorState: EditorState;
  onToggle: (style: string) => void;
}> = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  // { label: 'Monospace', style: 'CODE' },
];

export const InlineStyleControls: FC<{
  editorState: EditorState;
  onToggle: (style: string) => void;
}> = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

export const LinkControl: FC<{
  editorState: EditorState;
  editor: any;
  onChange: (e: EditorState) => void;
}> = ({ editorState, editor, onChange }) => {
  const urlRef = useRef<HTMLInputElement>(null);
  const [showInput, setShowInput] = useState(false);
  const [value, setValue] = useState<string>();

  // this.onChange = e => this.setState({ value: e.target.value });

  const change = (e: SyntheticEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const promptFor = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();

    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      setShowInput(true);
      setValue(url);
      if (urlRef && urlRef.current) {
        urlRef.current.focus();
      }
    }
  };

  const confirm = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: value },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    onChange(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey,
      ),
    );
    setShowInput(false);
    setValue('');
    editor.focus();
  };

  const onInputKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (13 === e.which) {
      confirm(e);
    }
  };

  const remove = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      onChange(RichUtils.toggleLink(editorState, selection, null));
      setShowInput(false);
      setValue('');
    } else {
      console.error('Normalement la selection devrait exister');
    }
  };

  const close = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    setShowInput(false);
    setValue('');
  };

  return (
    <div className="RichEditor-controls RichEditor-linkControl">
      {showInput && (
        <div className="popup-input">
          <input
            ref={urlRef}
            onChange={change}
            onKeyDown={onInputKeyDown}
            type="text"
            value={value}
          />
          <div className="buttons">
            <button onMouseDown={confirm} type="button">
              Valider
            </button>
            <button onMouseDown={remove} type="button">
              Retirer
            </button>
            <button onMouseDown={close} type="button">
              Fermer
            </button>
          </div>
        </div>
      )}
      <button
        className="RichEditor-styleButton"
        onMouseDown={promptFor}
        type="button"
      >
        Link
      </button>
    </div>
  );
};

const findLinkEntities = (
  block: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  block.findEntityRanges(character => {
    const entityKey = character.getEntity();

    return (
      entityKey !== null &&
      'LINK' === contentState.getEntity(entityKey).getType()
    );
  }, callback);
};

const Link: FC<{ contentState: ContentState; entityKey: any }> = ({
  children,
  contentState,
  entityKey,
}) => {
  const { url } = contentState.getEntity(entityKey).getData();

  return <a href={url}>{children}</a>;
};

export const decorator = new CompositeDecorator([
  {
    component: Link,
    strategy: findLinkEntities,
  },
]);
