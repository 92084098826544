import { getFirestore } from '@innedit/innedit';
import moment from 'moment';
import slug from 'slug';

import {
  USER_CREATE,
  USER_IS_LOGOUT,
  USER_LOAD_ERROR,
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  USER_REMOVE,
  USER_UPDATE,
} from '../../actionTypes';
import { watch as watchBoutiques } from '../Boutique/actions';

moment.locale('fr');

slug.defaults.mode = 'rfc3986';

export function load(user) {
  return dispatch => {
    dispatch({
      type: USER_LOAD_REQUEST,
    });

    // 1. On charge l'utilisateur
    getFirestore()
      .collection('users')
      .doc(user.uid)
      .get()
      .then(newUser => {
        if (newUser && newUser.exists && !newUser.data().deleted) {
          dispatch({
            type: USER_LOAD_SUCCESS,
            user: newUser,
          });
        } else {
          throw new Error("L'utilisateur n'existe pas ou a été supprimé");
        }

        // 2. On charge les boutiques
        return dispatch(watchBoutiques(newUser));
      })
      .catch(error => {
        dispatch({
          message: error,
          type: USER_LOAD_ERROR,
        });
      });
  };
}

/**
 * isLogout
 *
 * @returns {{type: string}}
 */
export function isLogout() {
  return {
    type: USER_IS_LOGOUT,
  };
}

/**
 * create
 *
 * @param {object} item
 * @returns {object}
 */
export function create(item) {
  return {
    payload: getFirestore().collection('users').add(item),
    type: USER_CREATE,
  };
}

/**
 * update
 *
 * @param {string} key
 * @param {object} item
 * @returns {object}
 */
export function update(key, item) {
  return {
    payload: getFirestore().collection('users').doc(key).update(item),
    type: USER_UPDATE,
  };
}

/**
 * remove
 *
 * @param {string} id
 * @returns {{payload: Promise<void>, type: string}}
 */
export function remove(id) {
  return {
    payload: getFirestore().collection('users').doc(id).update({
      deleted: true,
      updatedAt: moment().toISOString(),
    }),
    type: USER_REMOVE,
  };
}
