import { ProduitData } from '@innedit/innedit';
import { change, connect, DataField, DataFieldProps } from 'dataformjs';
import React, { FC, SyntheticEvent, useState } from 'react';

import Button from '../../../../../../components/Button';
import IconRedo from '../../../../../../icons/Redo';

interface SkuProps extends DataFieldProps {
  boutique?: firebase.default.firestore.DocumentSnapshot;
  formName: string;
}

const Sku: FC<
  SkuProps & {
    changeSku: (sku: string) => void;
    date?: string;
  }
> = ({ boutique, changeSku, date, name, label, placeholder }) => {
  const [inProgress, setInProgress] = useState<boolean>();

  const handleGenerate = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    generateSku();
  };

  const generateSku = async () => {
    if (!inProgress) {
      setInProgress(true);

      const produitData = new ProduitData({ boutique });
      const sku = await produitData.generateNextSku(date);
      changeSku(sku);
      setInProgress(false);
    }
  };

  return (
    <DataField
      componentType="input"
      customAction={
        <Button
          iconLeft={IconRedo}
          onClick={handleGenerate}
          status="field-action"
        />
      }
      fieldProps={{
        className: 'border-r-0 rounded-r-none',
      }}
      label={label}
      name={name}
      placeholder={placeholder}
      type="text"
    />
  );
};

const mapStateToProps = (globalState: any, ownProps: SkuProps) => {
  const { values } = globalState.form[ownProps.formName];

  return {
    date: values.publishedAt,
    sku: values.sku,
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: SkuProps) => ({
  changeSku: (sku: string) => dispatch(change(ownProps.formName, 'sku', sku)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sku);
