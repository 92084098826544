/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
import colors from './colors';
import spacing from './spacing';

const control = {
  bg: {
    color: {
      base: colors.primary[50],
      focus: colors.primary[200],
    },
  },
  border: {
    color: {
      base: colors.primary[300],
      hover: colors.primary[400],
    },
    size: { base: 1 },
    type: 'solid',
  },
  icon: {
    color: {
      base: colors.primary,
    },
    size: {
      base: 16,
    },
  },
  size: {
    height: {
      base: '2.5rem',
    },
  },
  padding: {
    x: {
      base: spacing.s,
      small: spacing.xs,
      icon: spacing.xl,
      iconSmall: spacing.xl,
    },
    y: {
      base: 0,
      small: 0,
    },
  },
  radius: {
    base: 0,
  },
};

export default control;
