import styled, { css } from 'styled-components';

export const BlocActionsEl = styled.div`
  align-items: start;
  display: flex;
  flex: 1;
  //justify-content: flex-end;
  margin-left: 1.5rem;

  h3 {
    margin-top: 0;
  }

  button + button {
    margin-left: 0.75rem;
  }
`;

export const BlocActionEl = styled.button`
  background: white;
  border-radius: 16px;
  border: 1px solid #e4e7ed;
  display: inline-block;
  padding: 0.75rem;
`;

export const BloacActionLigneEl = styled.div`
  margin-bottom: 0.75rem;
  button + small {
    :before {
      content: ' ';
      margin-left: 0.375rem;
    }
  }
`;

export const BulleEl = styled.div<{ isUser?: boolean }>`
  background: ${props => (props.isUser ? '#e4e7ed' : '#4b71fc')};
  border-radius: ${props =>
    props.isUser ? '16px 16px 16px 0' : '16px 16px 0 16px'};
  color: ${props => (props.isUser ? 'inherit' : 'white')};
  display: inline-block;
  max-width: 80%;
  padding: 0;

  h2 {
    margin: 0.75rem;
    font-size: 18px;
  }
  p {
    white-space: pre-line;
    margin: 0.75rem;
  }

  ul {
    margin: 0.75rem;
    padding: 0 0 0 1rem;
  }
`;

export const BulleProductEl = styled(BulleEl)`
  display: flex;
  flex-direction: ${props => (props.theme.mobile ? 'column' : 'row')};
  background: white;

  > div {
    flex: 1;
    border: 1px solid #e4e7ed;
    border-top: none;
    border-bottom-right-radius: 16px;
    font-size: 90%;

    ${props =>
      props.theme.mobile
        ? css``
        : css`
            border-top-right-radius: 16px;
          `};
  }
`;

export const BullePhotoEl = styled.img`
  border-radius: 16px 16px 0 0;
  display: block;

  ${props =>
    props.theme.mobile
      ? css`
          width: 100%;
          border-radius: 16px 16px 0 0;
        `
      : css`
          width: 40%;
          border-radius: 16px 0 0 0;
        `};
`;

export const DateEl = styled.div`
  font-size: 12px;
  margin-bottom: 0.75rem;
  text-align: center;
  width: 100%;
`;

export const LigneEl = styled.div<{ isUser?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isUser ? 'row' : 'row-reverse')};
  margin-bottom: 0.75rem;
`;

export const MessageEl = styled.div`
  margin: 1.5rem;
`;
