import styled from 'styled-components';

import { spacing } from '../../styles/theme';
import { ControlSC } from '../../utils/getControlStyle';

const Textarea = styled(ControlSC).attrs({ as: 'textarea' })`
  line-height: 1.5rem;
  padding-top: ${spacing[3]};
  padding-bottom: ${spacing[3]};
  box-sizing: border-box;
`;

export default Textarea;
