import { ContactData, MessageData } from '@innedit/innedit';
import { DataProps } from 'dataformjs';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import Message from '../../../../../../../components/Message';
import Reponse from '../../../../../../../components/Reponse';
import MessageType from '../../../../../../CMS/types/DocumentData/Message';

interface MessagesProps extends DataProps {
  boutique?: firebase.default.firestore.DocumentSnapshot;
  docId: string;
}

const Messages: FC<MessagesProps> = ({ boutique, docId }) => {
  const [messages, setMessages] =
    useState<firebase.default.firestore.QueryDocumentSnapshot<MessageType>[]>();

  useEffect(() => {
    const contact = new ContactData({ boutique });
    const unsub: () => void = contact.watchMessages(docId, querySnapshot => {
      setMessages(querySnapshot.docs);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [boutique, docId]);

  if (!messages) {
    return null;
  }

  const handleAddResponse = async (response: string): Promise<void> => {
    await MessageData.create(
      {
        contact: docId,
        text: response,
        user: false,
      },
      {
        boutique,
      },
    );
    window.scrollTo(0, document.body.scrollHeight);
  };

  const handleMarkAsAnswered = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    ContactData.markAsAnswered(docId, {
      boutique,
    });
  };

  return (
    <>
      {messages.map(message => (
        <Message key={message.id} boutique={boutique} doc={message} />
      ))}

      <Reponse
        onClickMarkAsAnswered={handleMarkAsAnswered}
        onSubmit={handleAddResponse}
      />
    </>
  );
};

export default Messages;
