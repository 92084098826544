import { WrappedFieldArrayProps } from 'dataformjs';
import React, { FC } from 'react';

import HOCGroup from '../../../../../../CMS/components/HOC/Group';
import { DimensionsProps } from './index';
import Specifique from './Specifique';

const Render: FC<WrappedFieldArrayProps & DimensionsProps> = ({ fields }) => {
  const handleAddOnClick = () => {
    fields.push({
      libelle: '',
      valeur: '',
    });
  };

  return (
    <HOCGroup
      addOnClick={handleAddOnClick}
      addText="Nouvelle dimension"
      title="Dimensions spécifiques"
    >
      {0 === fields.length && <p>Aucun dimension spécifique</p>}
      {fields.map((field, idx, f) => (
        <Specifique
          key={field}
          data={f.get(idx)}
          handleRemove={() => fields.remove(idx)}
          name={field}
        />
      ))}
    </HOCGroup>
  );
};

export default Render;
