import styled from 'styled-components';

import arrow from '../../images/font-awesome/solid/arrow-up.svg';
import ellipsisH from '../../images/font-awesome/solid/ellipsis-h.svg';

export const Options = styled.div`
  margin-top: 1.5rem;
`;

export const Response = styled.div`
  position: sticky;
  bottom: 0;
  display: block;
  left: 0;
  right: 0;

  button {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: #4b71fc;
    box-sizing: border-box;
    position: absolute;
    bottom: 3px;
    right: 3px;
    color: transparent;
    &:before {
      content: '';
      mask-image: url(${arrow});
      mask-repeat: no-repeat;
      background-color: white;
      display: block;
      width: 18px;
      height: 18px;
      margin: 7px;
      mask-position: center;
    }
  }

  .show-options {
    left: 0;
    right: auto;
    background-color: #e4e7ed;

    &:before {
      mask-image: url(${ellipsisH});
      background-color: #34313f;
   
  }
`;

export const Textarea = styled.textarea`
  display: block;
  appearance: none;
  background: #fff;
  border: #e4e7ed 1px solid;
  border-radius: 18px;
  box-sizing: border-box;
  height: 38px;
  line-height: 24px;
  outline: none;
  padding: 7px 44px 7px 10px;
  position: relative;
  resize: none;
  text-align: left;
  white-space: pre-wrap;
  width: calc(100% - 40px);
  left: 40px;
  word-wrap: break-word;
  overflow: hidden;
`;
