import { ContactData, ContactType } from '@innedit/innedit';
import { DataField, DataFieldProps } from 'dataformjs';
import React, { FC, ReactNode } from 'react';

interface SelectProps extends DataFieldProps {
  boutique?: firebase.default.firestore.DocumentSnapshot;
  formName: string;
  name: string;
}

const Select: FC<SelectProps> = ({
  boutique,
  formName,
  name,
  placeholder,
  ...newProps
}) => {
  const handleLoadOptions = async (
    inputValue: string,
  ): Promise<ContactType[]> => {
    const contactModel = new ContactData({ boutique });
    const contacts = await contactModel.search(inputValue);

    return contacts.hits;
  };

  const handleFormatOptionLabel = (
    option: any,
    {
      context,
    }: {
      context: 'menu' | 'value';
      inputValue?: string;
      selectValue?: any | any[];
    },
  ): ReactNode => {
    if ('string' === typeof option) {
      return option;
    }

    if ('value' === context) {
      return `${option.get('firstName')} ${option.get('lastName')}`;
    }

    return (
      <>
        <strong>{`${option.get('firstName')} ${option.get(
          'lastName',
        )}`}</strong>
        <span className="block">{option.get('email')}</span>
      </>
    );
  };

  const handleIsOptionSelected = (
    option: any,
    optionsSelected: any[],
  ): boolean => optionsSelected.some((o: any) => o.id === option.id);

  const handleLoadingMessage = (): ReactNode => 'Recherche en cours';
  const handleNoOptionsMessage = (): ReactNode => 'Aucun contact';

  return (
    <DataField
      {...newProps}
      cacheOptions
      componentType="async-select"
      defaultOptions
      formName={formName}
      formatOptionLabel={handleFormatOptionLabel}
      isOptionSelected={handleIsOptionSelected}
      loadOptions={handleLoadOptions}
      loadingMessage={handleLoadingMessage}
      name={name}
      noOptionsMessage={handleNoOptionsMessage}
      placeholder={placeholder}
    />
  );
};

export default Select;
