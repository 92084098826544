import { Link } from 'gatsby';
import React, { FC, ReactNode, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Date from './Date';
import Default from './Default';
import Tooltip from './Tooltip';

export type ButtonSizeType = 'sm' | 'lg';
export type ButtonStatusType =
  | string
  | 'card-action'
  | 'field-action'
  | 'delete-action'
  | 'menu-action'
  | 'tab-action'
  | 'link'
  | 'secondary';

export interface ButtonProps {
  as?: any;
  className?: string;
  clickOnVisible?: boolean;
  clipboard?: boolean;
  disabled?: boolean;
  iconColor?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  iconSize?: number;
  id?: string;
  isInfinite?: boolean;
  link?: string;
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  size?: ButtonSizeType;
  square?: boolean;
  status?: ButtonStatusType;
  target?: string;
  text?: string;
  to?: string;
  tooltip?: string;
  type?: string;
  value?: string;
}

const Button: FC<ButtonProps> = ({
  children,
  className,
  id,
  link,
  text,
  tooltip,
  type,
  value,
  ...others
}) => {
  const { t } = useTranslation();

  switch (type) {
    case 'tooltip': {
      if (!tooltip) {
        return <div>tooltip obligatoire</div>;
      }

      return (
        <Tooltip
          {...others}
          className={className}
          clipboard
          text={text}
          value={tooltip}
        >
          {children}
        </Tooltip>
      );
    }

    case 'date':
      return (
        <Date {...others} className={className} text={text} value={value}>
          {children}
        </Date>
      );

    case 'link':
      if (link) {
        return (
          <Link className={className} to={link}>
            {children}
            {text && <span>{t(text)}</span>}
          </Link>
        );
      }

      return <div>No link</div>;

    default:
      return (
        <Default {...others} className={className} text={text}>
          {children}
        </Default>
      );
  }
};

export default Button;
