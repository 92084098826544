/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
import { DefaultTheme } from 'styled-components';

import borderRadius from './borderRadius';
import colors from './colors';
import control from './control';
import font from './font';
import screens from './screens';
import spacing from './spacing';

interface ColorType {
  50?: string;
  100?: string;
  200?: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export interface DefaultThemeProps extends DefaultTheme {
  colors: {
    [key: string]: ColorType | string;
  };
  control: any;
  font: any;
  mobile: boolean;
  screens: { [key: string]: string };
  spacing: { [key: string]: string };
  transition: { [key: string]: any };
  webapp: boolean;
}

const transition = {
  timing: {
    base: '0.3s',
    fast: '0.15s',
  },
  easing: {
    base: 'ease-in-out',
  },
};

const theme: DefaultThemeProps = {
  colors,
  control,
  font,
  screens,
  spacing,
  transition,
  mobile: false,
  webapp: false,
};

export default theme;
export { colors, control, font, borderRadius, spacing, screens, transition };
