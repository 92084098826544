import { WrappedFieldArrayProps } from 'dataformjs';
import React, { FC } from 'react';

import HOCGroup from '../../../../../../CMS/components/HOC/Group';
import { TraductionsProps } from './index';
import Item from './Item';

const Render: FC<
  WrappedFieldArrayProps &
    TraductionsProps & {
      nbEnable: number;
      options: { disabled?: boolean; label: string; value: string }[];
    }
> = ({ fields, formName, nbEnable, options }) => {
  const handleAddOnClick = () => {
    fields.push({
      key: '',
    });
  };

  return (
    <HOCGroup
      addOnClick={nbEnable > 0 ? handleAddOnClick : undefined}
      addText="Ajouter une langue"
      title="Langues"
    >
      {0 === fields.length && <p>Aucune traduction</p>}
      {fields.length > 0 && (
        <div className="flex flex-col space-y-3">
          {fields.map((field, idx, f) => (
            <Item
              key={field}
              data={f.get(idx)}
              formName={formName}
              handleRemove={() => fields.remove(idx)}
              name={field}
              options={options}
            />
          ))}
        </div>
      )}
    </HOCGroup>
  );
};

export default Render;
