import { DataField } from 'dataformjs';
import React, { FC, memo, SyntheticEvent } from 'react';

import Button from '../../../../../../../components/Button';
import IconDelete from '../../../../../../../icons/Delete';

interface ItemProps {
  data: {
    id: string;
    libelle: string;
    valeur: string;
  };
  handleRemove: (event: SyntheticEvent<HTMLButtonElement>) => void;
  name: string;
}
const Item: FC<ItemProps> = ({ data, handleRemove, name }) => {
  console.info('render item', data);

  return (
    <div className="flex space-x-1 mb-3">
      <DataField
        autoComplete="off"
        componentType="input"
        label={data.libelle}
        name={`${name}.valeur`}
        placeholder={data.libelle}
        type="text"
        value={data.valeur}
        wrapperProps={{
          className: 'mb-0 flex-1',
        }}
      />
      {}
      <div className="flex space-x-1 self-end">
        {data.id && (
          <Button status="secondary" tooltip={data.id} type="tooltip" />
        )}
        <Button
          iconLeft={IconDelete}
          onClick={handleRemove}
          size="sm"
          status="delete-action"
        />
      </div>
    </div>
  );
};

export default memo(
  Item,
  (prev, next) =>
    prev.name === next.name && prev.data.valeur === next.data.valeur,
);
