import React, { FC, ReactNode } from 'react';

import IconAdd from '../../icons/Add';
import IconBack from '../../icons/Back';
import IconDelete from '../../icons/Delete';
import IconNext from '../../icons/Next';

const Icon: FC<{ color?: string; size?: number; value: ReactNode }> = ({
  color,
  size,
  value,
}) => {
  if (typeof React.Component === typeof value) {
    const IconCmp: any = value as React.ReactElement;

    return <IconCmp color={color} size={size} />;
  }

  let IconCmp;

  switch (value) {
    case 'add': {
      return <IconAdd color={color} size={size} />;
    }

    case 'back': {
      return <IconBack color={color} size={size} />;
    }

    case 'next': {
      return <IconNext color={color} size={size} />;
    }

    case 'remove': {
      return <IconDelete color={color} size={size} />;
    }

    default:
  }

  return <div>{IconCmp}</div>;
};
export default Icon;
