import { DataProps, useSelector } from 'dataformjs';
import fetch from 'node-fetch';
import React, { FC, SyntheticEvent } from 'react';

// const usePreviousAccountId = (value?: string): string | undefined => {
//   const ref = useRef<string>();
//
//   useEffect(() => {
//     ref.current = value;
//   });
//
//   return ref.current;
// };

interface KYCProps extends DataProps {
  boutique: firebase.default.firestore.DocumentSnapshot;
  formName: string;
  docId: string;
}

const KYC: FC<KYCProps> = ({ boutique, docId, formName }) => {
  const { stripeAccountId, stripeAccountToken } = useSelector(
    (globalState: any) => globalState.form[formName].values,
  );
  const handleCreateAccountOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    if (boutique) {
      let url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/boutiques/${boutique.id}`;
      url += `/accounts/create?marchandId=${docId}`;
      fetch(url)
        .then(result => console.info('result', result))
        .catch(error => {
          console.error(error.message);
        });
    }
  };

  const handleCreateAccountTokenOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    let url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/boutiques/${boutique.id}`;
    url += `/tokens/create?marchandId=${docId}&type=account`;
    fetch(url)
      .then(result => console.info('result', result))
      .catch(error => {
        console.error(error.message);
      });
  };

  return (
    <div>
      <h2>Vérification d&apos;identité</h2>
      {!stripeAccountId && (
        <fieldset>
          <legend>Account</legend>
          {stripeAccountToken && (
            <button onClick={handleCreateAccountOnClick} type="button">
              Créer un compte connecté
            </button>
          )}
          {!stripeAccountToken && (
            <button onClick={handleCreateAccountTokenOnClick} type="button">
              Créer un token account
            </button>
          )}
        </fieldset>
      )}
    </div>
  );
};

export default KYC;
