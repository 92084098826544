export const IS_MOBILE = 'IS_MOBILE';
export const IS_WEBAPP = 'IS_WEBAPP';

export const USER_LOAD_REQUEST = 'USER_LOAD_REQUEST';
export const USER_LOAD_SUCCESS = 'USER_LOAD_SUCCESS';
export const USER_LOAD_ERROR = 'USER_LOAD_ERROR';
export const USER_IS_LOGOUT = 'USER_IS_LOGOUT';

export const BOUTIQUE_CHANGE = 'BOUTIQUE_CHANGE';
export const BOUTIQUES_LOAD_REQUEST = 'BOUTIQUES_LOAD_REQUEST';
export const BOUTIQUES_LOAD_SUCCESS = 'BOUTIQUES_LOAD_SUCCESS';

export const USER_REMOVE = 'USER_REMOVE';

export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';

export const POSTS_WATCH = 'POSTS_WATCH';
export const POST_CREATE = 'POST_CREATE';
export const POST_UPDATE = 'POST_UPDATE';
export const POSTS_RESET = 'POSTS_RESET';

export const FEATURES_WATCH = 'FEATURES_WATCH';
export const FEATURES_RESET = 'FEATURES_RESET';
export const FEATURES_LIST = 'FEATURES_LIST';
export const FEATURE_CREATE = 'FEATURE_CREATE';
export const FEATURE_UPDATE = 'FEATURE_UPDATE';
export const FEATURE_REMOVE = 'FEATURE_REMOVE';

export const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE';
export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';

export const DOCS_RESET = 'DOCS_RESET';
export const RESET = 'RESET';

//  Boutique
export const BOUTIQUES_WATCH = 'BOUTIQUES_WATCH';
export const BOUTIQUES_UNSUBSCRIBE = 'BOUTIQUES_UNSUBSCRIBE';
export const BOUTIQUES_LIST = 'BOUTIQUES_LIST';
export const BOUTIQUE_CREATE = 'BOUTIQUE_CREATE';
export const BOUTIQUE_UPDATE = 'BOUTIQUE_UPDATE';
export const BOUTIQUE_REMOVE = 'BOUTIQUE_REMOVE';
export const BOUTIQUES_RESET = 'BOUTIQUES_RESET';
export const BOUTIQUE_PHOTOS_ADD = 'BOUTIQUE_PHOTOS_ADD';

export const LIST_COLLECTION_SET = 'LIST_COLLECTION_SET';
export const LIST_DOCS_SET = 'LIST_DOCS_SET';
export const LIST_SEARCH_SET = 'LIST_SEARCH_SET';
export const LIST_TAB_SET = 'LIST_TAB_SET';
